.t-activities-price-summary {
  :root {
    --primary-color: '';
  }

  .t-color-subscription-primary {
    color: var(--primary-color);
  }

  & .t-strike {
    text-decoration: line-through;
  }

  & .t-label-discount {
    color: #2ECC71;
    display: inline-flex;
    justify-content: flex-start;
    font-size: 14px;
    font-weight: 500;
    text-align: left;
  }

  & .t-text-callout {
    font-weight: 700;
  }
}
