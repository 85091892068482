@import "@/Library/UiKit/src/scss/uikit/utilities/variables/_variables.scss";
@import "@/Library/UiKit/src/scss/uikit/mixins/_mixins-prefixer.scss";
@import "@/Library/UiKit/src/scss/uikit/mixins/_mixins-transform.scss";
@import "@/Library/UiKit/src/scss/uikit/mixins/_mixins-animations.scss";
@import "@/Library/UiKit/src/scss/uikit/mixins/_mixins-miscellaneous.scss";
@import "@/Library/UiKit/src/scss/uikit/mixins/_mixins-responsive.scss";

.c-card-federated-search {
  border-bottom: 1px solid $color-grey-light;

  &:last-child {
    padding-bottom: 0;
    margin-bottom: 0;
    border-bottom: 0;
  }

  & .featured-search-card {
    &:hover {
      & .featured-search-card-featured {
        &-image {
          @include transform(scale(1.1));
        }
      }
    }

    &-title {
      @include mobile {
        font-size: 16px !important;
      }
    }

    & .category-type {
      &-icon {
        font-size: 20px;
      }
    }

    &-featured {
      height: 170px;

      @include mobile {
        height: 90px;
      }

      &-image {
        z-index: 3;
        height: 100%;
        width: 100%;
        object-fit: cover;
        margin: auto;

        @include all-zero;
        @include transition($primary-transition);
      }

      & .broken-image {
        font-size: 64px;
        z-index: 1;
        top: 50%;
        left: 50%;
        margin: auto;

        @include transform(translate3d(-50%, -50%, 0));

        @include mobile {
          font-size: 28px;
        }
      }
    }

    &-view-detail {
      line-height: 0;
    }
  }

  & .t-card-skeleton-image {
    @include mobile {
      height: 80px;
    }
  }
}
