@import '../../../../assets/scss/variables.scss';
@import "@/Library/UiKit/src/scss/uikit/utilities/variables/_variables.scss";
@import "@/Library/UiKit/src/scss/uikit/mixins/_mixins-responsive.scss";

.shortlist-header {
  & .hidden-desktop {
    display: none;
    margin-top: 32px;

    @media screen and (max-width: $screen-sm-max) {
      display: block;

      .t-text-align-right {
        text-align: center !important;
      }
    }
  }

  & .no-p-l-mobile {
    padding-left: 0;
  }

  & .no-p-r-mobile {
    padding-right: 0;
  }

  & .buttons-container {
    width: 350px;
  }

  & .mates-rates-desktop {
    display: block;

    @include mobile {
      display: none;
    }
  }

  & .mates-rates-mobile {
    display: none;

    @include mobile {
      display: block;
    }
  }

  & .t-navbar-top-right-menus-list-item-child-head {
    & h2 {
      font-weight: normal;
    }
  }
}
