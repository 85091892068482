.c-advertising-page {
  max-width: 768px;
  margin: auto;

  .advertising-banner-image {
    flex-basis: 30%;
  }

  .advertising-banner-description {
    flex-basis: 70%;
  }
}
