@import "@/Library/UiKit/src/scss/uikit/utilities/variables/_variables.scss";
@import "@/Library/UiKit/src/scss/uikit/mixins/_mixins-prefixer.scss";
@import "@/Library/UiKit/src/scss/uikit/mixins/_mixins-responsive.scss";

.travlr-ui {
  & .recent-searches-container {
    & .t-p-16 {
      padding: 16px;
    }

    & .border-grey {
      border: 1px $color-grey-lightest solid;
    }

    & .z-index-1 {
      z-index: 1;
    }

    & .t-m-w-70 {
      max-width: 70%;
    }

    & .cursor-pointer {
      cursor: pointer;
    }

    & .t-0-l-0 {
      top: 0;
      left: 0;
    }

    & .t-0-r-0 {
      top: 0;
      right: 0;
    }

    & .fs-14 {
      font-size: 14px !important;
    }

    & .fs-12 {
      font-size: 12px;
    }

    & .h-80 {
      height: 80px;
    }

    & .c-carousel {
      & .flickity-slider {
        & .carousel-item {
          left: -15%;
          position: relative;
          width: 85%;

          @include media-up(sm) {
            left: 3%;
            width: 50%;
          }

          @include media-up(md) {
            left: 3%;
            width: 30%;
          }

          @include media-up(lg) {
            left: 0;
            width: 25%;
          }

          &:first-child {
            left: 0;
          }
        }
      }

      & a {
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}
