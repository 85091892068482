@import "@/Library/UiKit/src/scss/uikit/utilities/variables/_variables.scss";
@import "@/Library/UiKit/src/scss/uikit/mixins/_mixins-prefixer.scss";
@import "@/Library/UiKit/src/scss/uikit/mixins/_mixins-responsive.scss";
@import "@/Library/UiKit/src/scss/uikit/mixins/_mixins-miscellaneous.scss";

.travlr-ui {
  & .tooltip {
    &__content {
      top: 100%;
      z-index: 100;
      max-width: 320px;
      width: max-content;

      @include mobile {
        max-width: 256px; // 320 - (32*2) --> container gutter
      }

      @media screen and (max-width: 375px) {
        max-width: 240px;
      }

      &--right {
        right: -4px;

        .tooltip__arrow {
          right: 6px;
        }
      }

      &--left {
        left: -4px;

        .tooltip__arrow {
          left: 6px;
        }
      }

      &--center {
        left: 50%;
        transform: translateX(-50%);

        .tooltip__arrow {
          left: 50%;
          transform: translateX(-50%);
        }
      }
    }

    &__icon {
      font-size: 18px;
    }

    &__arrow {
      top: -4px;
      border-bottom: 6px solid $color-grey-darkest;
      border-left: 6px solid transparent;
      border-right: 6px solid transparent;
      height: 0;
      position: absolute;
      width: 0;
    }
  }
}
