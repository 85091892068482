.c-accommodations-checkout-summary {
  :root {
    --primary-color: '';
  }

  .t-color-subscription-primary {
    color: var(--primary-color);
  }

  .flex-1 {
    flex: 1;
  }

  .custom-star-size .t-icon {
    font-size: 16px;
  }

  .t-input-postcode {
    justify-content: space-between;
    align-items: center;
  }

  @media (max-width: 767px) {
    .contact-us-section {
      padding-left: 16px;
    }
  }

  .trv-refund-protect-section {
    .tooltip--input-form::before {
      content: unset;
    }

    .tooltip--input-form {
      text-align: center;
      margin-top: 16px;
      width: 100%;
    }

    &.has-error {
      border: 2px solid #EF4444;
    }
  }

  & .t-inner-html > ul {
    list-style-position: unset !important;
    padding-left: 16px !important;
  }

  .vip-plan-offer {
    .subscription-plan-benefits {
      padding: 2em;
      background-color: #F9FAFB;
      line-height: 2em;

      .benefit-lists {
        font-size: 14px;
        font-weight: 400;
        font-style: normal;
        font-stretch: normal;
        letter-spacing: normal;

        .benefit-item {
          display: flex;
          font-size: 12px;

          .t-icon {
            color: #F8BB91;
          }
        }
      }
    }

    .vip-offer-warning {
      display: flex;
      font-size: 12px;
    }

    .vip-price-label {
      display: flex;
      align-items: center;

      span {
        font-size: 12px;
        margin: 0 10px;
      }

      img {
        width: 36px;
      }
    }
  }

  .t-text-callout {
    font-weight: 700;
  }
}
