@import "@/Library/UiKit/src/scss/uikit/utilities/variables/_variables.scss";
@import "@/Library/UiKit/src/scss/uikit/mixins/_mixins-responsive.scss";

.t-pointer-events-none {
  pointer-events: none;
}

.checkboxes-wrap-menu {
  left: 0;
  max-height: 50vh;
  -webkit-opacity: 0;
  -moz-opacity: 0;
  opacity: 0;
  overflow-y: auto;
  -moz-transition: opacity 0.2s ease-in-out;
  -ms-transition: opacity 0.2s ease-in-out;
  -o-transition: opacity 0.2s ease-in-out;
  transition: opacity 0.2s ease-in-out;
  -webkit-transition: opacity 0.2s ease-in-out;
  z-index: 5;
  pointer-events: none;

  @include mobile {
    max-height: 35vh;
  }

  &.dropdown {
    &::after {
      display: none;
    }
  }

  &.active {
    pointer-events: auto;
    -webkit-opacity: 1;
    -moz-opacity: 1;
    opacity: 1;
    transition: opacity 0.2s ease-in-out;
    -moz-transition: opacity 0.2s ease-in-out;
    -webkit-transition: opacity 0.2s ease-in-out;
    -ms-transition: opacity 0.2s ease-in-out;
    -o-transition: opacity 0.2s ease-in-out;
    visibility: visible;
  }

  & .w-100-mobile {
    @include mobile {
      width: 100%;
      text-align: center !important;

      .align-right-mobile {
        text-align: right !important;
      }
    }
  }

  & .hidden-mobile {
    @include mobile {
      display: none;
    }
  }

  & .m-b-8-mobile {
    @include mobile {
      margin-bottom: 8px;
    }
  }

  & .p-l-0 {
    padding-left: 0;
  }

  & .no-hover {
    &:hover {
      background-color: transparent !important;
    }

    &:active {
      background-color: transparent !important;
    }

    &:focus {
      background-color: transparent !important;
    }
  }

  & .t-btn-anchor {
    @include mobile {
      line-height: 1.3;
    }
  }
}

.c-dropdown-checkboxes {
  & .dropdown-input {
    &.extra-p-l {
      padding-left: 44px !important;
    }
  }

  & .t-icon {
    &.icon-location {
      left: 16px;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  .t-btn-anchor {
    color: #9A9A9A !important;
  }
}
