@import '~flickity/dist/flickity.css';
@import "@/Library/UiKit/src/scss/uikit/utilities/variables/_variables.scss";
@import "@/Library/UiKit/src/scss/uikit/mixins/_mixins-responsive.scss";

.c-carousel-card-trending-destinations {
  position: relative;

  & .carousel-item {
    padding-right: 8px;
    padding-left: 8px;
  }

  & /deep/ .flickity {
    &-prev-next-button {
      background: white;
      width: 34px;
      height: 34px;

      svg {
        width: 35%;
        height: 35%;
        top: 35%;
        left: 35%;

        path {
          fill: black;
        }
      }

      @include mobile {
        display: none !important;
      }

      &.next {
        right: -10px;
      }

      &.previous {
        left: -10px;
      }

      &:disabled {
        opacity: 0;
        visibility: hidden;
      }
    }

    &-button-icon {
      path {
        fill: white;
      }
    }
  }
}
