@import "@/Library/UiKit/src/scss/uikit/utilities/variables/_variables.scss";
@import "@/Library/UiKit/src/scss/uikit/mixins/_mixins-prefixer.scss";
@import "@/Library/UiKit/src/scss/uikit/mixins/_mixins-responsive.scss";
@import "~/assets/scss/variables.scss";

.c-subscription-faq {
  @include tablet {
    .display-grid-tablet {
      display: grid;
      grid-template-columns: auto auto;
    }
  }

  .image-label {
    width: 56px;
    height: 56px;
    margin-top: -56px;
    border-radius: 8px 8px 0 0;
    clip-path: polygon(0 0, 100% 0, 100% 100%, 50% 82%, 0 100%);
  }

  .max-size-icon {
    width: 32px;
    height: 32px;
  }

  .text-label {
    width: fit-content;
    margin-top: -48px;
    margin-left: -24px;
    border-radius: 8px 0 8px 0;
  }

  .border-bottom {
    border-radius: 0 0 8px 8px;
  }

  // Global Start
  .t-btn {
    border-radius: 100px !important;

    .t-icon {
      padding-right: 0;
    }
  }

  .plans-section,
  .already-vip-section {
    @include mobile {
      .t-text-align-left-mobile {
        text-align: left !important;
      }
    }
  }

  .plans-section.with-max-width,
  .already-vip-section {
    .t-container {
      max-width: 800px;
    }
  }

  .t-btn-inverted {
    background-color: #FFF !important;
    color: $color-grey-800 !important;
  }

  .t-background-grey-800 {
    background-color: #1F2937;
  }

  .t-inner-html {
    ul,
    ol {
      padding-left: 24px;
      margin-top: 0;
      margin-bottom: 8px;

      li {
        margin-bottom: 8px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    ul {
      list-style: disc !important;
    }

    p {
      margin-bottom: 8px;
    }
  }

  .t-accordion-item-header {
    height: auto;
    padding-top: 8px;
    padding-bottom: 8px;
  }

  @include mobile {
    .t-section-extra-large {
      padding: 40px 0;
    }
  }
  // Global End

  // Hero Section Start
  .hero-section {
    .hero-title {
      font-size: 64px;
      font-weight: 700;
      line-height: 56px;
    }

    .inner-left {
      max-width: 350px;
    }

    .mask-bg {
      margin-top: -20px;

      @include mobile {
        margin-top: 0;
        transform: scale(1.3) !important;
      }
    }

    .floating-discount {
      width: 140px;
      height: 140px;
      padding: 12px;
      border-radius: 100px;
      top: 250px;
      left: -50px;
      transform: rotate(-10deg);
      z-index: 5;

      @include mobile {
        left: 15px;
        top: 180px;
      }

      @include tablet {
        left: 120px;
        top: 350px;
      }
    }

    .floating-balloon {
      top: 110px;
      left: -20px;
      z-index: 5;
    }

    .floating-starfish {
      bottom: 120px;
      right: 0;
      z-index: 5;
    }

    .floating-mask {
      top: -25px;
      left: 0;
    }
  }
  // Hero Section End

  // Upgrade Plans Start
  .plans-section {
    .plan-free-margin {
      margin-top: auto;
    }

    .plan-min-height {
      min-height: 500px;

      @include mobile {
        min-height: auto;
      }

      @include tablet {
        min-height: 530px;
      }
    }

    .height-100 {
      height: 100%;

      @include mobile {
        height: auto;
      }

      @include tablet {
        height: 100%;
      }
    }

    .second-plan-min-height {
      min-height: 430px;

      @include mobile {
        min-height: auto;
      }

      @include tablet {
        min-height: 450px;
      }
    }

    .plan-item {
      transition: all 0.3s ease;

      &:hover {
        transform: translateY(-10px);
      }
    }

    .plan-title {
      letter-spacing: 1.6px;
    }

    .t-btn-default {
      border-color: $color-grey-400 !important;
      color: $color-grey-900 !important;

      &:active,
      &:hover {
        background-color: transparent !important;
      }
    }
  }
  // Upgrade Plans End

  //Unlock VIP Start
  .unlock-vip-section {
    .left-container {
      .mask-bg {
        height: 480px;

        @include mobile {
          height: 360px;
        }

        @include tablet {
          height: 500px;
        }
      }

      .mask-container {
        top: 0;
        right: 0;
        transform: scale(1.05);
      }
    }

    .right-container {
      .inner-content {
        max-width: 480px;
      }
    }
  }
  //Unlock VIP End

  // FAQ Start
  .faq-section {
    .c-accordion-item > .t-accordion-item {
      border-bottom: $primary-border !important;
    }

    .c-accordion {
      &:last-child {
        .c-accordion-item > .t-accordion-item {
          border-bottom: none !important;
        }
      }
    }
  }
  // FAQ End
}
