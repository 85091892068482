@import "ClientApp/assets/scss/variables";

.tcn-gift-card-checkout-input {
  margin: 20px auto;

  &__message {
    padding: 6px 16px;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: 0.2px;
    color: #374044;
    border-radius: 4px;
    background-color: #EFF0F0;
    margin-bottom: 15px;
    margin-top: 15px;

    &--error {
      color: #F45252;
      border-radius: 4px;
      background-color: #FDEDED;
    }
  }
}

.tcn-gift-card-checkout-input-applied {
  background-color: rgba(45, 204, 211, 0.1);
  border-radius: 4px;
  padding: 14px 0;

  &__ticket {
    vertical-align: middle;
    margin: 20px 20px 20px 20px;
  }

  &__card-details {
    margin-bottom: 8px;
  }

  &__card-number {
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #374044;
    margin-right: 5px;
  }

  &__card-pre-balance {
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #6BA4B8;
  }

  &__amount {
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: 0.2px;
    color: #374044;
    margin-bottom: 5px;

    &--positive {
      font-weight: 600;
      color: #78CC4E;
    }
  }

  &__card-post-balance {
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: 0.2px;
    color: #374044;
  }

  &__delete {
    margin-top: 20px;
    display: block;
    transform: translateX(-20px);
  }
}

.tcn-gift-card-checkout-input-editing {
  &__delete {
    margin-top: 43px;
  }
}

.tcn-gift-card-checkout-input-editing-cancel {
  text-align: right;
  color: #6BA4B8;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  line-height: 24px;
  vertical-align: middle;
}
