@import "@/Library/UiKit/src/scss/uikit/utilities/variables/_variables.scss";
@import "@/Library/UiKit/src/scss/uikit/mixins/_mixins-responsive.scss";

.c-embed-video-layer-widget {
  .video-wrapper {
    padding-top: 56.25%; // 16:9 Aspect Ratio (divide 9 by 16 = 0.5625)

    /deep/ {
      iframe {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 100%;
        border: none;
      }
    }
  }
}
