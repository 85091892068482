@import "@/Library/UiKit/src/scss/uikit/utilities/variables/_variables.scss";
@import "@/Library/UiKit/src/scss/uikit/mixins/_mixins-responsive.scss";

.c-description-section-with-map-layer-widget {
  /deep/ {
    .collapsible-opened {
      .collapsible__action::after {
        opacity: 0;
      }
    }

    .collapsible__action {
      position: relative;

      &::after {
        content: '';
        position: absolute;
        width: 100%;
        height: 20px;
        background: linear-gradient(180deg, hsla(0, 0%, 100%, 0.1), hsla(0, 0%, 100%, 0.3) 50%, hsla(0, 0%, 100%, 0.75) 75%, hsla(0, 0%, 100%, 0.95));
        z-index: 1;
        bottom: 0;
        left: 0;
      }
    }
  }

  .t-inner-html {
    /deep/ {
      font-size: 16px;
      line-height: 24px;

      p,
      span,
      li {
        font-size: 16px !important;
        line-height: 24px;
        margin-bottom: 0;
      }

      p {
        margin-bottom: 12px;
      }

      a {
        text-decoration: underline;
        font-weight: 600;
        color: $color-grey-darkest;
      }

      ol,
      ul {
        padding-left: 20px;

        &:not(:last-child) {
          margin-bottom: 12px;
        }
      }

      ul {
        list-style: disc;
      }

      ol {
        list-style: decimal;
      }

      strong,
      b {
        font-weight: bold;
      }
    }
  }
}
