@import "@/Library/UiKit/src/scss/uikit/utilities/variables/_variables.scss";
@import "@/Library/UiKit/src/scss/uikit/mixins/_mixins-prefixer.scss";
@import "@/Library/UiKit/src/scss/uikit/mixins/_mixins-placeholders.scss";
@import "@/Library/UiKit/src/scss/uikit/mixins/_mixins-animations.scss";
@import "@/Library/UiKit/src/scss/uikit/mixins/_mixins-responsive.scss";
@import "@/Library/UiKit/src/scss/uikit/mixins/_mixins-text.scss";
@import "@/Library/UiKit/src/scss/uikit/mixins/_mixins-icon.scss";
@import "@/Library/UiKit/src/scss/uikit/mixins/_mixins-opacity.scss";
@import "@/Library/UiKit/src/scss/uikit/mixins/_mixins-border-radius.scss";
@import "@/Library/UiKit/src/scss/uikit/mixins/_mixins-miscellaneous.scss";
@import "@/Library/UiKit/src/scss/uikit/mixins/_mixins-transform.scss";

.t-bookings-flights-detail {
  & .content-left-section {
    min-height: 1px;
  }

  .main-card {
    border-radius: 4px;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.2);

    .main-card-child {
      padding: 24px;

      &.left {
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
      }

      &.right {
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
      }
    }
  }

  .c-flight-title {
    display: flex;
    align-items: center;
  }
}
