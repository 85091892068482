@import "@/Library/UiKit/src/scss/uikit/utilities/variables/_variables.scss";
@import "@/Library/UiKit/src/scss/uikit/mixins/_mixins-responsive.scss";

.c-hero-slider {
  position: relative;

  .back-button {
    @include media-up(sm) {
      display: none;
    }
  }

  & /deep/ .flickity {
    &-button {
      width: 48px;
      height: 48px;
      background: rgba(#374044, 0.5);

      &-icon {
        left: 25%;
        top: 25%;
        width: 50%;
        height: 50%;

        path {
          fill: white;
        }
      }
    }

    &-prev-next-button {
      &.next {
        right: 24px;
      }

      &.previous {
        left: 24px;
      }
    }

    &-viewport {
      backdrop-filter: blur(50px);
    }
  }
}
