@import "@/Library/UiKit/src/scss/uikit/utilities/variables/_variables.scss";
@import "@/Library/UiKit/src/scss/uikit/mixins/_mixins-responsive.scss";
@import "~/assets/scss/variables.scss";
$color-grey-disabled: #F2F2F2;
$color-grey-disabled-dark: #CECECE;
$color-range-date: #666C6F;
$color-checkinout-date: #374044;
$color-yellow: #F59E0B;

.c-hotelbeds-booking-widget {
  .booking {
    &-option {
      @include media-up(md) {
        margin-right: 32px;
      }

      @include media-up(xl) {
        margin-right: 80px;
      }

      .option {
        &-item {
          border: 1px solid $color-grey-300;
          padding: 32px;
          border-radius: 12px;
          cursor: pointer;
          transition: all 0.3s ease;

          &.selected,
          &:hover:not(.disabled) {
            border-color: $color-grey-900;
          }

          &.disabled {
            cursor: not-allowed;
            background-color: $color-grey-200 !important;
            opacity: 0.25;
            border-color: $color-grey-200 !important;
          }

          &:last-child {
            margin-bottom: 0;
          }
        }
      }

      // override <trv-carousel-slide-show />
      .c-carousel-slide-show {
        .all-photos-btn {
          .t-btn-inverted {
            background-color: rgba(0, 0, 0, 0.5);
            border: none;
            height: 34px;
            padding: 10px;

            svg {
              path {
                fill: #FFF;
              }
            }

            .t-body-text {
              display: none;
            }
          }
        }
      }
    }

    &-summary {
      @include media-up(md) {
        box-shadow: 0 4px 6px -2px #0000000D;
        box-shadow: 0 10px 15px -3px #0000001A;
        border: 1px solid $color-grey-200;
        border-radius: 16px;
      }

      @include mobile {
        padding: 0;
        border-top: 1px solid #E0E0E0;
      }

      .counter {
        width: auto;

        &__box {
          border: none;
          height: 24px;
          width: 150px;
          display: flex;
          justify-content: flex-end;
        }

        &__button {
          background-color: transparent;
          border-radius: 100%;
          width: 24px;
          height: 24px;
          display: flex;
          align-items: center;
          justify-content: center;
          transition: all 0.3s ease;
          border: 1px solid #000;

          &:hover {
            background-color: #000;
            color: #FFF !important;
          }

          .t-icon {
            line-height: normal;
          }
        }

        &__field {
          border: none;
        }
      }

      .departure-date {
        background-color: $color-grey-100;
      }

      .divider-package {
        @include media-up(md) {
          border-bottom: 1px solid #E0E0E0;
        }
      }

      .featured-image {
        width: 125px;
        height: 125px;
        min-width: 125px;

        img {
          object-fit: cover;
          height: 100%;
          width: 100%;
          border-radius: 12px;
        }

        @include mobile {
          width: 100%;
          height: 200px;
        }

        &-wrapper {
          @include mobile {
            flex-direction: column;
          }
        }
      }

      .total-nights {
        @include mobile {
          flex-direction: column;
          align-items: flex-start;
        }
      }

      .room-left-badge {
        .t-alert-alt {
          display: inline-block;
          width: auto;

          &-body {
            padding: 4px 8px;
          }
        }
      }
    }
  }

  .extras-wrapper {
    border: 0 !important;
    padding: 12px 0 !important;
    border-radius: 0 !important;

    .accordion__button {
      border: 0 !important;
      padding: 12px 0 !important;
      border-radius: 0 !important;

      &:hover {
        color: $color-grey-darkest !important;
      }

      .t-heading-5 {
        font-size: 16px !important;
        line-height: 20px !important;
        font-weight: normal !important;
      }
    }

    .deal-sku-allotment {
      border-radius: 12px !important;
      border-top: 1px solid #E5E7EB !important;
    }
  }

  .disabled {
    opacity: 0.5;
    cursor: not-allowed;
    pointer-events: none;
  }

  // calendar
  .calendar-wrapper {
    border: 1px solid $color-grey-300;
    border-radius: 12px;

    .el-range-editor {
      &.el-input__inner {
        width: 100%;
        border: 0;
      }
    }

    .calendar-note {
      border-top: 1px solid $color-grey-300;
    }

    .fc {
      &-day {
        &-today {
          background-color: transparent;
        }
      }

      &-day-other,
      &-day-disabled {
        // background-color: $color-grey-disabled;
        visibility: hidden;

        > div {
          height: 1px;
        }
      }

      &-daygrid-body {
        width: 100% !important;
      }

      &-daygrid-event-harness {
        text-align: center;
      }

      &-h-event {
        display: inline-block;
        background-color: $color-grey-darkest;
        border: none;
        margin-left: 0 !important;
        margin-right: 0 !important;
      }

      &-event-title {
        font-size: 10px;
        font-weight: 600;
        line-height: 1;
        padding: 3px 6px;
        text-align: center;
        vertical-align: middle;

        @include media-up(xl) {
          font-size: 11px;
        }
      }

      &-toolbar {
        justify-content: flex-start;

        &-chunk {
          &:first-child {
            position: absolute;
            right: 52px;
          }

          &:last-child {
            position: absolute;
            right: 24px;
            color: $color-grey-500;
          }

          .fc-icon {
            color: $color-grey-500;
          }
        }
      }

      &-header-toolbar {
        margin-bottom: 0;
        background-color: $color-grey-100;
        padding: 24px;
        border-top-left-radius: 12px;
        border-top-right-radius: 12px;
      }

      &-toolbar-title {
        font-size: 16px;
      }

      &-col-header {
        margin-bottom: 20px;
      }

      &-button {
        // !important way to override plugin's styles
        background: none !important;
        border: none !important;
        box-shadow: none !important;
        color: $color-black;
        padding: 0;

        &:hover,
        &:focus {
          color: $color-black;
        }

        &[disabled] {
          color: $color-grey-darker;
        }
      }

      &-daygrid-day {
        transition: all 0.15s ease-in-out;
        cursor: default;
        width: 50px;
        max-width: 50px;
        height: 50px;
        padding-bottom: 10px;

        @include mobile {
          width: 40px;
          max-width: 40px;
          height: 40px;
        }

        &-number {
          color: $color-grey-darkest;
        }

        &:not(.fc-daygrid-day-available) {
          color: $color-grey-disabled-dark;
          text-decoration: line-through;

          a {
            color: $color-grey-disabled-dark;
          }

          .fc-daygrid-day-frame {
            &:hover {
              border-color: transparent !important;
            }
          }
        }

        &-frame {
          display: flex;
          width: 50px;
          height: 50px;
          flex-direction: column;
          justify-content: center;
          margin: 0 auto;
          align-items: center;
          // background-color: $color-grey-disabled;
          border-radius: 100%;
          border: 1px solid transparent;
          transition: all 0.15s ease-in-out;

          @include mobile {
            width: 40px;
            height: 40px;
          }

          &:hover {
            border-color: #000;
          }
        }

        &-top {
          min-height: 0;
          justify-content: center;
        }

        &-events {
          min-height: 0 !important;
          margin-bottom: 0 !important;
          pointer-events: none;
        }

        &-available {
          background: $color-white;
          cursor: pointer;
          font-weight: bold;
        }

        &-canstay {
          background-color: $color-white;
        }

        &-surcharge {
          & .fc-daygrid-day-frame {
            position: relative;

            &::before {
              background-color: $color-yellow;
              bottom: 8px;
              border-radius: 4px;
              height: 4px;
              left: 50%;
              position: absolute;
              transform: translateX(-50%);
              width: 8px;
            }
          }
        }

        &-check-in-out,
        &-checkin-range {
          // background-color: $color-grey-100;
          background-color: transparent;
          border-radius: 100%;
        }

        &-check-in-out {
          color: $color-white !important;

          .fc-daygrid-day-number {
            color: $color-white !important;
          }

          .fc-daygrid-day-frame {
            background-color: $color-checkinout-date;

            &::before {
              background-color: #FFF !important;
            }
          }
        }

        &-checkin-range {
          background-color: $color-grey-100;
        }
      }

      &-view {
        table {
          border: none;
          font-size: 14px;
          padding: 24px;
          width: 100% !important;

          @include media-up(md) {
            padding: 48px 32px 0 32px;
          }

          tr {
            td,
            th {
              border: none;
            }
          }

          thead {
            border: none;

            tr {
              td {
                border: none;
              }

              th {
                border: none;
                color: $color-grey-darker;
                font-weight: 400;
                padding-bottom: 0;

                a {
                  color: $color-grey-darker;
                }
              }
            }
          }

          tbody {
            tr {
              td {
                .fc-scroller-harness {
                  td {
                    // padding-bottom: 16px;
                  }
                }

                table {
                  border: none;
                }
              }
            }
          }
        }

        > table {
          > tbody {
            > tr {
              > td {
                border: none;
              }
            }
          }
        }
      }
    }
  }

  // package type
  .skeleton-wrapper {
    .t-card-skeleton-image {
      border-radius: 4px;
      height: 100%;
      width: 100%;
    }
  }

  // book later
  .booklater-wrapper {
    border: 1px solid $color-grey-300;
    border-radius: 12px;

    .divider {
      border-bottom: 1px solid $color-grey-300;
    }

    .icon {
      &-item {
        width: 40px;
        height: 40px;
        border-radius: 100%;
        align-items: center;
        justify-content: center;
      }
    }
  }

  // purchasable
  .deal-sku-v2__purchasable {
    @include tablet {
      flex-direction: column;
    }

    @include mobile {
      flex-direction: column;
    }
  }

  // sku filter
  .filter-wrapper {
    .filter-item {
      transition: all 0.3s ease;

      &:hover,
      &.active {
        border-color: $color-grey-darkest;
        background-color: $color-grey-100;
      }
    }
  }

  // rate comments
  .rate-comments {
    &-term {
      font-size: 12px;

      &:last-child {
        border-top: 1px solid $color-grey-disabled-dark;
        padding-top: 8px;
        margin-top: 8px;
      }
    }
  }
}
