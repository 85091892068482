@import '~flickity/dist/flickity.css';
@import "@/Library/UiKit/src/scss/uikit/utilities/variables/_variables.scss";
@import "@/Library/UiKit/src/scss/uikit/mixins/_all.scss";

.c-carousel-image {
  position: relative;

  img {
    width: 300px;
    height: 300px;
    object-fit: cover;

    @include mobile {
      width: 140px;
      height: 140px;
    }
  }

  & .carousel-item {
    padding-right: 8px;
    padding-left: 8px;
  }

  & /deep/ .flickity {
    &-prev-next-button {
      display: none;

      @include mobile {
        display: none !important;
      }

      &.next {
        right: 8px;
        display: none;
      }

      &.prev {
        left: 0;
        display: none;
      }

      &:disabled {
        opacity: 0;
        visibility: hidden;
      }
    }

    &-button-icon {
      path {
        fill: white;
      }
    }
  }
}
