@import "@/Library/UiKit/src/scss/uikit/utilities/variables/_variables.scss";
@import "@/Library/UiKit/src/scss/uikit/mixins/_mixins-responsive.scss";
$color-grey-disabled: #F2F2F2;
$color-grey-disabled-dark: #CECECE;
$color-range-date: #666C6F;
$color-checkinout-date: #374044;
$color-yellow-transparent: rgba(255, 204, 0, 0.5);

.travlr-ui {
  & .calendar-book {
    left: 0;
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s ease-in-out;
    top: 0;
    z-index: 1800;

    &--opened {
      opacity: 1;
      visibility: visible;
    }

    &--edit {
      & .calendar-book {
        &__inner {
          padding-bottom: 195px;
        }
      }
    }

    &__traveller {
      span {
        font-weight: normal;
      }
    }

    &__inner {
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
      height: 100%;
      padding-bottom: 240px;

      @include media-up(md) {
        height: auto;
        padding-bottom: 0 !important;
      }

      &.accommodation {
        padding-bottom: 385px;
      }
    }

    & .t-w-40 {
      width: 40%;
    }

    & .t-w-60 {
      width: 60%;
    }

    & .selectbox {
      &__select {
        padding-bottom: 11px;
        padding-top: 11px;
      }
    }

    &__availabletime {
      z-index: 1006;

      &.selectbox {
        &--disabled {
          & .selectbox__select {
            background-color: $color-grey-lighter;
          }
        }
      }

      & .selectbox {
        &__select {
          @include media-up(md) {
            background-color: $color-grey-lightest;
          }
        }
      }

      & .dropdown {
        &__inner {
          max-height: 110px;
        }
      }

      &--bottom {
        & .dropdown {
          bottom: 100%;
          margin-bottom: 8px;
          margin-top: 0;
          top: auto;

          &::after {
            bottom: -8px;
            top: auto;
            transform: rotate(180deg);
          }
        }
      }
    }

    &__package {
      width: 100%;

      @include media-up(md) {
        max-width: 80%;
        width: auto;
      }

      &.selectbox {
        &--disabled {
          opacity: 0.5;
        }
      }

      & .selectbox {
        &__select {
          background-color: transparent;
          border: none;
          padding: 0 28px 0 4px;
        }

        &__caret {
          color: $color-white;
          font-weight: bold;
        }

        &__icon {
          color: $color-white;
          right: 0;
        }

        &__dropdown {
          font-size: 14px;
          min-width: 250px;

          & .dropdown {
            &__label {
              display: block;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              width: 100%;
            }
          }
        }
      }
    }

    &__overlay {
      background-color: rgba($color-black, 0.55);
    }

    &__container {
      height: 100%;
      left: 50%;
      max-width: 752px;
      position: absolute;
      top: 0;
      transform: translateX(-50%);
      width: 100%;

      @include media-up(md) {
        height: auto;
        top: 50%;
        transform: translate(-50%, -50%);
      }

      @include media-up(lg) {
        max-width: 800px;
      }
    }

    &__body {
      height: 100%;
      overflow-y: auto;
      padding: 16px;

      @include media-up(md) {
        height: auto;
        overflow-y: visible;
        padding: 24px;
      }

      &--rounded {
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;

        & .bg-helper {
          border-bottom-right-radius: 4px;
        }
      }

      & .bg-helper {
        height: 100%;
        right: 0;
        top: 0;
        z-index: 1;
      }

      & .t-col-md-7 {
        @include media-up(md) {
          padding-right: 16px;
        }
      }
    }

    &__footer {
      @include media-up(md) {
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
      }

      & .footer {
        padding: 16px;

        &__item {
          &--left {
            @include media-up(md) {
              padding: 24px 16px 24px 24px;
              border-bottom-left-radius: 4px;
            }
          }

          &--right {
            @include media-up(md) {
              padding: 24px 24px 24px 16px;
              border-bottom-right-radius: 4px;
            }
          }
        }
      }

      &.t-background-white {
        & .footer {
          &__item {
            &--left {
              @include media-up(md) {
                padding-top: 0;
              }
            }

            &--right {
              @include media-up(md) {
                padding-top: 0;
              }
            }
          }
        }
      }
    }

    &__header {
      box-shadow: 0 0 5px rgba($color-grey-darkest, 0.3);
      padding: 16px;
      z-index: 2;

      @include media-down(sm) {
        background-color: $color-white !important;
      }

      @include media-up(md) {
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
        box-shadow: none;
      }

      h5 {
        @include media-down(sm) {
          color: $color-black;
          text-align: center;
        }

        @include media-up(md) {
          font-size: 18px;
        }
      }

      .t-btn {
        color: $color-grey-darkest;
        left: 16px;
        transform: translateY(-50%);
        top: 50%;

        @include media-up(md) {
          color: $color-white;
          left: auto;
          right: 16px;
        }

        & .t-icon {
          font-size: 20px;
        }
      }
    }

    &__info {
      padding-top: 24px;

      @include media-up(md) {
        padding-top: 0;
        padding-left: 16px;
      }

      .bg-helper {
        border-top: 1px solid $color-grey-lighter;
        height: 100%;
        left: -8px;
        right: -8px;
        top: 0;
      }

      & .checkinout {
        font-size: 14px;

        @include media-up(lg) {
          font-size: 16px;
        }
      }
    }

    &__bottom {
      background-color: $color-white;
      border-top: 1px solid $color-grey-lighter;
      bottom: 0;
      left: 0;
      padding: 16px;
      position: fixed;
      width: 100%;
      z-index: 1007;

      @include media-up(md) {
        background-color: transparent;
        border-top: none;
        bottom: auto;
        left: auto;
        padding: 0;
        position: relative;
        z-index: 10;
      }

      & .price-summary {
        padding-right: 25px;

        @include media-up(md) {
          padding-right: 0;
        }

        &__accordion {
          height: 0;
          opacity: 0;
          overflow: hidden;
          visibility: hidden;
          transition: all 0.3s ease-in-out;

          @include media-up(md) {
            height: auto !important;
            opacity: 1 !important;
            visibility: visible !important;
          }

          &.opened {
            height: auto;
            opacity: 1;
            visibility: visible;
          }
        }

        & .t-icon {
          right: -25px;
          top: 50%;
          transform: translateY(-50%);
        }
      }

      & .t-btn {
        @include media-up(md) {
          font-size: 18px !important;
          height: 48px;
          line-height: 48px;
          padding: 0 20px !important;
        }
      }
    }

    & .fc {
      &-day-other,
      &-day-disabled {
        // background-color: $color-grey-disabled;
        // position: absolute;
        visibility: hidden;

        > div {
          height: 1px;
        }
      }

      &-daygrid-event-harness {
        text-align: center;
      }

      &-h-event {
        display: inline-block;
        background-color: $color-grey-darkest;
        border: none;
        margin-left: 0 !important;
        margin-right: 0 !important;
      }

      &-event-title {
        font-size: 10px;
        font-weight: 600;
        line-height: 1;
        padding: 3px 6px;
        text-align: center;
        vertical-align: middle;

        @include media-up(xl) {
          font-size: 11px;
        }
      }

      &-header-toolbar {
        margin-bottom: 16px;
      }

      &-toolbar-title {
        font-size: 16px;
      }

      &-button {
        // !important way to override plugin's styles
        background: none !important;
        border: none !important;
        box-shadow: none !important;
        color: $color-black;
        padding: 0;

        &:hover,
        &:focus {
          color: $color-black;
        }

        &[disabled] {
          color: $color-grey-darker;
        }
      }

      &-daygrid-day {
        background-color: $color-grey-disabled;
        transition: all 0.15s ease-in-out;
        cursor: default;

        &:not(.fc-daygrid-day-available) {
          color: $color-grey-disabled-dark;

          a {
            color: $color-grey-disabled-dark;
          }
        }

        &-number {
          color: $color-grey-darkest;
        }

        &-frame {
          display: flex;
          height: 50px;
          flex-direction: column;
          justify-content: center;
          padding: 3px;
        }

        &-top {
          min-height: 0;
          justify-content: center;
        }

        &-events {
          min-height: 0 !important;
          margin-bottom: 0 !important;
          pointer-events: none;
        }

        &-available {
          background: $color-white;
          cursor: pointer;
          font-weight: bold;
        }

        &-canstay {
          background-color: $color-white;
        }

        &-surcharge {
          & .fc-daygrid-day-frame {
            position: relative;

            &::before {
              background-color: $color-yellow-transparent;
              bottom: 4px;
              border-radius: 4px;
              height: 4px;
              left: 50%;
              position: absolute;
              transform: translateX(-50%);
              width: 22px;
            }
          }
        }

        &-check-in-out,
        &-checkin-range {
          color: $color-white !important;

          a {
            color: $color-white !important;
          }
        }

        &-check-in-out {
          background-color: $color-checkinout-date;
          color: $color-white !important;

          .fc-daygrid-day-number {
            color: $color-white !important;
          }

          .fc-daygrid-day-frame {
            background-color: $color-checkinout-date;

            &::before {
              background-color: #FFF !important;
            }
          }
        }

        &-checkin-range {
          background-color: $color-range-date;
        }
      }

      &-view {
        table {
          border: none;
          font-size: 14px;

          tr {
            td,
            th {
              border: none;
            }
          }

          thead {
            border: none;

            tr {
              td {
                border: none;
              }

              th {
                border: none;
                color: $color-grey-darker;
                font-weight: 400;
                padding-bottom: 8px;
              }
            }
          }

          tbody {
            tr {
              td {
                text-align: left;

                table {
                  border: none;
                }
              }
            }
          }
        }

        > table {
          > tbody {
            > tr {
              > td {
                border: none;
              }
            }
          }
        }
      }

      .fc-col-header-cell-cushion {
        color: $color-range-date !important;
      }
    }

    & .t-card-skeleton {
      overflow: hidden;
      transition: all 0.3s ease;

      &-image {
        border-radius: 4px;
        height: 100%;
        width: 100%;

        @include media-up(md) {
          float: left;
        }
      }

      &-text {
        display: none;
      }
    }

    & .guest-room-select {
      // max-width: 295px;

      & .dropdown {
        &__entry {
          max-height: 250px !important;

          @include media-up(md) {
            max-height: 320px !important;
          }
        }
      }

      & .counter {
        &__label {
          font-size: 12px !important;
          max-width: 66px;

          strong {
            display: block;
          }
        }
      }
    }

    & .t-form {
      & .t-input-group {
        & .t-input-control-indicator {
          top: 50%;
          transform: translateY(-50%);
        }
      }

      & .t-input-control {
        &--disabled {
          cursor: not-allowed;

          &:hover {
            color: $color-grey-darker !important;

            & .t-input-control-indicator {
              border-color: #8E9497 !important;
            }
          }
        }

        &.t-input-control-checkbox {
          font-weight: normal;
        }
      }
    }

    .disable {
      pointer-events: none;
    }

    .t-text-italic {
      font-style: italic;
    }
  }
}
