@import "@/Library/UiKit/src/scss/uikit/utilities/variables/_variables.scss";
@import "@/Library/UiKit/src/scss/uikit/mixins/_mixins-prefixer.scss";
@import "@/Library/UiKit/src/scss/uikit/mixins/_mixins-responsive.scss";
@import "@/Library/UiKit/src/scss/uikit/mixins/_mixins-transform.scss";

.group-header-container {
  & .see-more-btn {
    padding-right: 0;
  }

  & .icon-angle-right {
    font-size: 14px;
  }

  & .w-120 {
    width: 120px;
  }

  & .t-heading-2 {
    @include mobile {
      font-size: 20px;
    }
  }
}
