@import "@/Library/UiKit/src/scss/uikit/utilities/variables/_variables.scss";
@import "@/Library/UiKit/src/scss/uikit/mixins/_mixins-responsive.scss";

.c-uninstall-extension-page {
  max-width: 960px;
  margin-left: auto;
  margin-right: auto;

  // override UIKit based on the design
  .t-box-with-shadow {
    box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
  }

  .t-form {
    .t-input-group {
      .t-input-control-indicator {
        height: 26px;
        width: 26px;

        &::after {
          font-size: 26px !important;
          width: 26px;
          height: 26px;
        }
      }

      .t-input-control {
        padding-left: 42px;
        font-size: 18px;
        line-height: 30px;
        font-weight: normal;
      }
    }
  }

  @include mobile {
    .t-input-textarea {
      height: 200px;
    }
  }
}
