.c-activities-select-quantity {
  transition: all ease-in-out 800ms;
  position: fixed !important;
  left: 0;
  z-index: 100;
  margin-bottom: 0;
  border: 0;
  background: white;
  border-top: 1px solid #DDD;
  border-radius: 0;
  padding: 8px 16px;
  bottom: -57px;

  &.active {
    bottom: 0;
  }

  & .activities-select-quantity {
    &__wording-height {
      height: 40px;
    }
  }
}
