.c-activities-headline-price {
  transition: all ease-in-out 800ms !important;
  bottom: -73px !important;
  z-index: 100;

  &.active {
    bottom: 0 !important;
  }

  & .t-strike {
    text-decoration: line-through;
  }
}
