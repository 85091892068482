@import "@/Library/UiKit/src/scss/uikit/utilities/variables/_variables.scss";
@import "@/Library/UiKit/src/scss/uikit/mixins/_mixins-responsive.scss";

.t-loading-screen-add-to-cart {
  .c-logo-plane-loading {
    margin: auto !important;
  }

  .trv-modal {
    background-color: rgba(0, 0, 0, 0.5) !important;
  }

  .trv-modal__header {
    display: unset;

    @include mobile {
      display: none;
    }
  }

  .trv-modal__body {
    padding: 20px 0 !important;
  }

  .trv-modal__content {
    @include mobile {
      height: unset !important;
    }
  }

  .t-modal-content {
    min-width: unset;
    width: 480px;

    @include mobile {
      height: unset;
      border-radius: $radius;
      width: 276px;
    }
  }
}
