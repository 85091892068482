@charset "utf-8";

@import "@/Library/UiKit/src/scss/uikit/utilities/variables/_variables.scss";
@import "@/Library/UiKit/src/scss/uikit/mixins/_mixins-prefixer.scss";
@import "@/Library/UiKit/src/scss/uikit/mixins/_mixins-placeholders.scss";
@import "@/Library/UiKit/src/scss/uikit/mixins/_mixins-animations.scss";
@import "@/Library/UiKit/src/scss/uikit/mixins/_mixins-responsive.scss";
@import "@/Library/UiKit/src/scss/uikit/mixins/_mixins-text.scss";
@import "@/Library/UiKit/src/scss/uikit/mixins/_mixins-icon.scss";
@import "@/Library/UiKit/src/scss/uikit/mixins/_mixins-opacity.scss";
@import "@/Library/UiKit/src/scss/uikit/mixins/_mixins-border-radius.scss";
@import "@/Library/UiKit/src/scss/uikit/mixins/_mixins-miscellaneous.scss";
@import "@/Library/UiKit/src/scss/uikit/mixins/_mixins-transform.scss";
@import "@/Library/UiKit/src/scss/uikit/elements/forms/_forms.scss";

.c-package-options {
  & .t-date-picker {
    /deep/ {
      & .t-input-global-datepicker {
        &:disabled,
        &.disabled {
          opacity: 0.5;
          cursor: not-allowed;
        }
      }

      .el-input__inner {
        border: 0;
        border-radius: 0;
        line-height: 38px;
        padding-right: 0;
        padding-left: 0;
        height: 38px;
        background-color: #FFF !important;

        &:disabled {
          opacity: 0.5;
          cursor: not-allowed;
        }

        @include placeholder {
          color: $color-grey-dark;
          font-size: 14px;
        }
      }

      .el-input__suffix {
        & .el-input__icon {
          opacity: 0;
          visibility: hidden;

          &.el-icon-circle-close {
            top: 6px !important;
            opacity: 1;
            visibility: visible;

            @include transform(translateY(0) !important);
            @include transition($primary-transition);
          }
        }
      }

      .el-icon-date {
        color: $color-grey-dark !important;
        position: absolute;
        top: 50%;
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
        font-size: 20px !important;
        height: auto;

        &::before {
          content: '\ea3e';
        }
      }

      .el-icon-circle-close {
        color: $color-grey-dark !important;
        position: absolute;
        top: 50%;
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
        font-size: 20px !important;
        height: auto;
        right: 0;

        &::before {
          content: '\ea53';

          @include icon-default;
        }
      }

      [class^="el-icon-"],
      [class*=" el-icon-"] {
        @include icon-default;
      }
    }
  }

  & .t-box-disable {
    z-index: 1;
  }

  & .t-strike {
    text-decoration-line: line-through;
  }
}

.booking-time-selection {
  & > p {
    font-size: 12px;
    padding: 10px 0;
  }
}
