@import "@/Library/UiKit/src/scss/uikit/utilities/variables/_variables.scss";

.c-bnpl-payto-faq {
  .payto-color {
    color: #FF7854 !important;
  }

  .faq {
    &-header {
      background: #F3F4F6;
    }

    &-footer {
      background: #F3F4F6;

      .content {
        max-width: 800px;
        margin: auto;
      }
    }

    &-item {
      border-bottom: 1px solid $color-grey-300;

      &.hide {
        overflow: hidden;

        .faq-item-body {
          height: 0;
        }
      }
    }
  }
}
