@import "@/Library/UiKit/src/scss/uikit/utilities/variables/_variables.scss";
@import "@/Library/UiKit/src/scss/uikit/mixins/_mixins-prefixer.scss";
@import "@/Library/UiKit/src/scss/uikit/mixins/_mixins-placeholders.scss";
@import "@/Library/UiKit/src/scss/uikit/mixins/_mixins-animations.scss";
@import "@/Library/UiKit/src/scss/uikit/mixins/_mixins-responsive.scss";
@import "@/Library/UiKit/src/scss/uikit/mixins/_mixins-text.scss";
@import "@/Library/UiKit/src/scss/uikit/mixins/_mixins-icon.scss";
@import "@/Library/UiKit/src/scss/uikit/mixins/_mixins-opacity.scss";
@import "@/Library/UiKit/src/scss/uikit/mixins/_mixins-border-radius.scss";
@import "@/Library/UiKit/src/scss/uikit/mixins/_mixins-miscellaneous.scss";
@import "@/Library/UiKit/src/scss/uikit/mixins/_mixins-transform.scss";

$humm: #F4742B;

.c-flight-checkout-form {
  & .content-left-section {
    min-height: 1px;
  }

  .btn-pay {
    &-hide {
      display: none !important;
    }

    &-active {
      display: block;
    }

    &-disabled {
      opacity: 0.5;
      cursor: not-allowed;
      position: relative;
      z-index: 999;
    }

    &-humm {
      background-color: $humm;
      font-weight: 600;
    }
  }

  & .t-inner-html > ul {
    list-style-position: unset !important;
    padding-left: 16px !important;
  }

  .hide-btn-pay {
    height: 0;
  }

  & .form-wizard {
    &-circle {
      width: 32px;
      height: 32px;
      line-height: 32px;
      border-radius: 100%;

      @include mobile {
        width: 28px;
        height: 28px;
        line-height: 28px;
      }
    }

    &-title {
      @include mobile {
        font-size: 16px;
      }
    }
  }

  & .country-code-select {
    opacity: 0;
  }

  & .phone-country-code {
    display: block;
    position: absolute;
    top: 50%;
    margin-left: 12px;
    pointer-events: none;

    @include transform(translateY(-50%));
  }

  .t-date-picker {
    @include tablet {
      width: 100%;

      .t-input-global-datepicker {
        min-width: 100% !important;
        max-width: 100% !important;
        width: 100% !important;
      }
    }

    /deep/ {
      .el-input__inner {
        border: 0;
        border-radius: 0;
        line-height: 38px;
        padding-right: 0;
        padding-left: 0;
        height: 100%;
        background-color: $color-grey-lightest;

        @include placeholder {
          color: $color-grey-dark;
          font-size: 14px;
        }
      }

      .el-input__suffix {
        & .el-input__icon {
          opacity: 0;
          visibility: hidden;

          &.el-icon-circle-close {
            top: 6px !important;
            opacity: 1;
            visibility: visible;

            @include transform(translateY(0) !important);
            @include transition($primary-transition);
          }
        }
      }

      .el-icon-date {
        color: $color-grey-dark !important;
        position: absolute;
        top: 50%;
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
        font-size: 20px !important;
        height: auto;

        &::before {
          content: '\ea3e';
        }
      }

      .el-icon-circle-close {
        color: $color-grey-dark !important;
        position: absolute;
        top: 50%;
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
        font-size: 20px !important;
        height: auto;
        right: 0;

        &::before {
          content: '\ea53';

          @include icon-default;
        }
      }

      [class^="el-icon-"],
      [class*=" el-icon-"] {
        @include icon-default;
      }
    }
  }

  #btn-paypal {
    width: 100% !important;

    &.disabled {
      opacity: 0.5;
      cursor: not-allowed;
      pointer-events: none;
    }
  }

  .checkout-information {
    font-size: 12px;
  }

  .consent-checkbox.t-input-control-indicator::after {
    color: transparent !important;
  }

  input:checked ~ .consent-checkbox.t-input-control-indicator::after {
    color: #FFF !important;
  }

  .trv-refund-protect-section {
    .tooltip--input-form::before {
      content: unset;
    }

    .tooltip--input-form {
      text-align: center;
      margin-top: 16px;
      width: 100%;
    }

    &.has-error {
      border: 2px solid #EF4444;
    }
  }

  @media (max-width: 767px) {
    .contact-us-section {
      padding-left: 16px;
    }
  }
}
