@import "@/Library/UiKit/src/scss/uikit/utilities/variables/_variables.scss";
@import "@/Library/UiKit/src/scss/uikit/mixins/_mixins-responsive.scss";

.c-hero-search {
  display: block;

  .location-input-wrapper {
    /deep/ .t-input-group {
      min-width: 425px;
      max-width: 425px;

      @include mobile {
        min-width: auto;
        max-width: 100%;
      }
    }
  }
}
