.c-guest-counter {
  & .guest-counter {
    &__input {
      cursor: pointer;

      & .t {
        &-input-text {
          cursor: pointer;
          display: inline-block;
          pointer-events: none;

          & .input {
            &-placeholder {
              position: relative;
              display: table-cell;
              padding-right: 16px;
              vertical-align: middle;
              color: #8E9497;
              cursor: pointer;
            }
          }
        }
      }
    }
  }
}
