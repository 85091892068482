@import "@/Library/UiKit/src/scss/uikit/utilities/variables/_variables.scss";
@import "@/Library/UiKit/src/scss/uikit/mixins/_mixins-responsive.scss";
@import "~/assets/scss/variables.scss";

.c-referral-detail-page {
  max-width: 768px;
  margin: auto;

  @include until(480px) {
    padding-top: 8px !important;
    padding-bottom: 8px !important;
  }

  .t-container {
    @include until(480px) {
      padding: 0 !important;
    }
  }

  .t-box {
    min-height: 574px;

    @include until(480px) {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
  }

  .t-form {
    .t-input-group {
      .t-input-label {
        &__normal {
          font-weight: normal;
        }
      }
    }

    .t-input-icon-group {
      & .t-input-icon-right {
        color: $color-grey-400;
        right: 16px;
      }

      & .t-input-text {
        &:disabled {
          opacity: 1;
          color: $color-grey-900;
        }
      }
    }

    .t-input-group-with-button {
      @include media-up(md) {
        table-layout: fixed;
        width: initial;

        .t-input-group:nth-of-type(1) {
          width: 80%;
        }
      }

      .send-invite-button {
        min-width: 150px;
      }
    }
  }

  .t-tabs {
    &__no-padding {
      padding: initial;
    }

    &__border-bottom {
      border-bottom-style: solid;
      border-bottom-color: $color-grey-300;
      border-width: 1px;
      border-radius: 0;
    }

    &-item {
      &.active {
        & .t-tabs-link {
          color: $color-grey-700;
          font-weight: 500;
        }
      }
    }

    &-link {
      color: $color-grey-300;

      &__no-padding {
        padding: initial;
      }
    }
  }

  .copy-link-tooltip {
    top: 0;
    display: none;
    left: 120px;
    background-color: rgba(0, 0, 0, 0.3);
    padding: 4px 8px;
    z-index: 100;

    &.active {
      display: inline-block;
    }
  }

  .referral-status {
    &-invited,
    .referral-claimed {
      background-color: $color-grey-200;
      color: $color-grey-500;
    }

    &-registered {
      background-color: $color-yellow-50;
      color: $color-warning-v2;
    }

    &-subscribed {
      background-color: $color-green-50;
      color: $color-green;
    }
  }

  .vertical-line {
    border-left: 1px solid $color-grey-400;
    height: 100%;
  }

  .referees {
    &-container {
      @include media-down(sm) {
        overflow-x: scroll;
        -ms-overflow-style: none;
        scrollbar-width: none;
      }

      &::-webkit-scrollbar {
        display: none;
      }
    }
    min-width: 500px;
    border-style: solid;
    border-radius: 8px;
    border-width: 1px;
    border-color: $color-grey-200;

    & th {
      border-bottom-style: solid;
      border-bottom-width: 1px;
      border-bottom-color: $color-grey-200;
    }
  }
}
