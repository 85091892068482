@import '~vue-multiselect/dist/vue-multiselect.min.css';
@import "@/Library/UiKit/src/scss/uikit/mixins/_mixins-prefixer.scss";
@import "@/Library/UiKit/src/scss/uikit/mixins/_mixins-transform.scss";

$humm: #F4742B;

.c-checkout-form {
  & .btn-pay {
    &-hide {
      display: none !important;
    }

    &-active {
      display: block;
    }

    &-disabled {
      opacity: 0.5;
      cursor: not-allowed;
      position: relative;
      z-index: 999;
    }

    &-humm {
      background-color: $humm;
      font-weight: 600;
    }
  }

  & .currency-disclaimer {
    padding: 16px;
    border: 1px solid #006272;
    border-radius: 4px;
    margin-bottom: 24px;
    background-color: #F8FDFE;

    p {
      color: #AAACAC !important;
      margin-top: 12px;
      font-size: 11px;

      &:first-child {
        margin-top: 0;
      }
    }
  }

  & .country-code-select {
    opacity: 0;
  }

  & .phone-country-code {
    display: block;
    position: absolute;
    top: 50%;
    margin-left: 12px;
    pointer-events: none;

    @include transform(translateY(-50%));
  }
}
