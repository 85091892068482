/** Style for Component: Shortlist Item **/
@import "../../../../assets/scss/variables.scss";

.c-shortlists {
  & .note-container {
    @media screen and (min-width: 768px) {
      height: 57px;
      overflow: hidden;
      padding-bottom: 12px;
      cursor: pointer;
    }

    & .separator {
      width: 100%;
      background: $color-light-grey;
      height: 1px;
    }

    & .note {
      vertical-align: top;
    }

    & .icon-note {
      margin-right: 4px;
    }

    & .expanded-note {
      display: table;
      position: absolute;
      padding: 10px 16px;
      top: 1px;
      left: 0;
      width: 100%;
      height: auto;
      z-index: 5;
      background: white;
      visibility: hidden;
      pointer-events: none;
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
    }

    @media screen and (min-width: 768px) {
      &.show {
        overflow: visible;

        & .expanded-note {
          visibility: visible;
          box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
        }
      }

      .special-note {
        max-width: 100%;
        word-wrap: normal;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    @media screen and (min-width: 1024px) {
      &:hover {
        overflow: visible;

        & .expanded-note {
          visibility: visible;
          box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
        }
      }
    }
  }

  & .c-card {
    & .t-card-content-footer {
      padding-top: 12px;
    }
  }

  & .price-section-ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    height: 18px;
  }
}

/* ************************************ */
