@import "@/Library/UiKit/src/scss/uikit/utilities/variables/_variables.scss";
@import "@/Library/UiKit/src/scss/uikit/mixins/_mixins-prefixer.scss";
@import "@/Library/UiKit/src/scss/uikit/mixins/_mixins-responsive.scss";
@import "@/Library/UiKit/src/scss/uikit/mixins/_mixins-transform.scss";

.travlr-ui {
  .l-accommodation {
    & .t-container {
      &.popular-deals {
        @include mobile {
          padding-left: 0;
          padding-right: 0;
        }
      }
    }

    & .t-hero-main {
      max-height: 100%;
      min-height: 100%;
      padding-bottom: 32px;
      padding-top: 32px;

      @include media-up(md) {
        padding-bottom: 64px;
        padding-top: 64px;
      }

      & .t-hero-main-content {
        display: block;
        height: auto;
        min-height: auto;
        max-height: 100%;
      }

      & .t-hero-main-background {
        max-height: 100%;
        min-height: 100%;
      }

      & .t-hero-main-content-list {
        display: block;
      }

      & .t-hero-main-content-list-title {
        display: block;
        height: auto;
        min-height: auto;

        & .t-hero-main-content-list-title-wrapper {
          display: block;
        }
      }

      & .t-hero-main-content-list-booking {
        background-color: transparent;
        padding-bottom: 0;
        padding-top: 0;
      }
    }

    & .t-btn--section {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
    }

    & .t-gap-x-24 {
      column-gap: 24px;
    }

    & .fs-18-mobile {
      @include mobile {
        font-size: 18px !important;
      }
    }

    & .fs-28 {
      font-size: 28px !important;
    }

    & .fs-16 {
      font-size: 16px !important;
    }

    & .mw-60-md {
      @include media-up(md) {
        max-width: 60%;
      }
    }

    & .fs-12-mobile {
      font-size: 12px !important;
    }

    & .price-container {
      height: 48px;
      vertical-align: bottom;
    }

    & .card-featured-accommodation {
      & .t-card-content-footer {
        height: 88px;

        & .t-card-content-footer-left-location {
          display: block;
          min-height: initial;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          width: 120px;
        }

        & .t-card-content-footer-right {
          white-space: nowrap;
        }
      }

      & .t-card-skeleton {
        overflow: hidden;

        & .t-card-skeleton-text {
          height: 100%;
        }
      }
    }

    & .c-bookafly-accomm-faq {
      background-color: #E5E7EB;

      & .faq-dropdown-button {
        border-radius: 50%;
        width: 36px;
        height: 36px;
      }
    }
  }
}
