@import "@/Library/UiKit/src/scss/uikit/utilities/variables/_variables.scss";
@import "@/Library/UiKit/src/scss/uikit/mixins/_mixins-prefixer.scss";
@import "@/Library/UiKit/src/scss/uikit/mixins/_mixins-responsive.scss";

.c-federated-search-result {
  & .skeleton-95 {
    width: 95%;
  }

  & .t-card-skeleton-image {
    @include mobile {
      height: 80px;
    }
  }
}
