@import "@/Library/UiKit/src/scss/uikit/utilities/variables/_variables.scss";
@import "@/Library/UiKit/src/scss/uikit/mixins/_mixins-responsive.scss";
@import "@/Library/UiKit/src/scss/uikit/mixins/_mixins-text.scss";

.travlr-ui {
  & .accordion {
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);

    &--opened {
      & .accordion {
        &__button {
          border-bottom-left-radius: 0 !important;
          border-bottom-right-radius: 0 !important;

          & .t-icon {
            transform: translateY(-50%) rotate(-180deg);
          }
        }
      }
    }

    &__button {
      background-color: $color-grey-50;
      border: 1px solid $color-grey-lighter;
      font-weight: bold;
      text-transform: capitalize;

      &:hover {
        color: $color-grey-dark;
      }

      & .t-icon {
        position: absolute;
        right: 16px;
        top: 50%;
        transform: translateY(-50%);
        transition: transform 0.2s ease;
      }
    }

    &__content {
      transition: height 0.2s ease;
      // Reset Body Typography
      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        font-weight: $weight-bold;

        @include typography-text-normal;
        margin: 12px 0;

        &:first-child {
          margin-top: 0;
        }

        &:last-child {
          margin-bottom: 0;
        }
      }

      & ul {
        list-style: disc;
        padding-left: 16px;

        &:not(:last-child) {
          margin-bottom: 12px;
        }
      }

      & a {
        font-weight: bold;
        color: $color-grey-dark;

        &:hover {
          color: $color-grey-darker;
          text-decoration: none;
        }
      }
    }

    &--outline {
      box-shadow: none;

      & .accordion {
        &__content {
          border-bottom-left-radius: 4px;
          border-bottom-right-radius: 4px;
          border-bottom: 1px solid  #DDD;
          border-left: 1px solid  #DDD;
          border-right: 1px solid  #DDD;
          position: relative;
          top: -4px;
          z-index: 1;
        }

        &-control {
          position: relative;
          z-index: 2;

          & .accordion__button {
            border-bottom: 1px solid #DDD;
          }
        }
      }
    }
  }
}
