@import "@/Library/UiKit/src/scss/uikit/utilities/variables/_variables.scss";
@import "@/Library/UiKit/src/scss/uikit/mixins/_mixins-responsive.scss";

.c-cruise-extension-page {
  max-width: 960px;
  margin-left: auto;
  margin-right: auto;

  // override UIKit based on the design
  .t-box-with-shadow {
    box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
  }

  .t-input-label {
    font-weight: normal !important;
  }

  @include mobile {
    .t-input-textarea {
      height: 200px;
    }
  }
}
