.c-image-carousel-layer-widget {
  .c-image {
    img {
      height: 600px;
      width: 100%;
      object-fit: cover;

      @media screen and (max-width: 1024px) {
        height: auto;
      }
    }
  }

  .flickity-prev-next-button {
    width: 36px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;

    &.next {
      right: 24px;
    }

    &.previous {
      left: 24px;
    }

    .flickity-button-icon {
      position: relative;
      top: auto;
      left: auto;
      width: 16px;
      height: 16px;
    }
  }
}
