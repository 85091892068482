@import "@/Library/UiKit/src/scss/uikit/utilities/variables/_variables.scss";
@import "@/Library/UiKit/src/scss/uikit/mixins/_mixins-prefixer.scss";
@import "@/Library/UiKit/src/scss/uikit/mixins/_mixins-placeholders.scss";
@import "@/Library/UiKit/src/scss/uikit/mixins/_mixins-animations.scss";
@import "@/Library/UiKit/src/scss/uikit/mixins/_mixins-responsive.scss";
@import "@/Library/UiKit/src/scss/uikit/mixins/_mixins-text.scss";
@import "@/Library/UiKit/src/scss/uikit/mixins/_mixins-icon.scss";
@import "@/Library/UiKit/src/scss/uikit/mixins/_mixins-opacity.scss";
@import "@/Library/UiKit/src/scss/uikit/mixins/_mixins-border-radius.scss";
@import "@/Library/UiKit/src/scss/uikit/mixins/_mixins-miscellaneous.scss";
@import "@/Library/UiKit/src/scss/uikit/mixins/_mixins-transform.scss";

.t-booking-management {
  .dropdown-active {
    width: 100%;
    padding: 9px 25px 9px 15px;
    border: $color-grey-light;
    border-radius: $radius;
    border-right: 16px solid transparent;
    outline: 1px solid $color-grey-light;
  }

  .t-heading-title {
    height: 40px;
  }

  @include mobile {
    .t-tabs-link {
      padding: 0 17px;
    }

    .card-wrapper {
      padding-left: 20px;
      padding-right: 20px;
    }

    .nav-wrapper {
      display: flex;
      flex-direction: row;
      overflow-x: auto;
      width: 100%;
      -ms-overflow-style: none;  /* Hide scrollbar for IE and Edge */
      scrollbar-width: none;  /* Hide scrollbar for Firefox */

      .tab-link {
        width: 90px;
      }

      &::-webkit-scrollbar {
        display: none; /* Hide scrollbar for Chrome, Safari and Opera */
      }
    }

    .title-options-section {
      flex-direction: column;
    }
  }

  .empty-box-image {
    max-width: 160px;
  }

  .empty-booking {
    height: 50vh;

    .detail {
      text-align: center;
    }
  }

  .image-section {
    height: 180px;
    width: 100%;
    background: $color-grey-lighter;

    &::before {
      position: absolute;
      top: 50%;
      left: 50%;
      -webkit-transform: translate3d(-50%, -50%, 0);
      transform: translate3d(-50%, -50%, 0);
      content: "\EA99";
      color: $color-grey-light;
      display: inline-block;
      font: normal normal normal 24px/1 TRAVLR !important;
      text-rendering: auto;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      font-size: 72px !important;
    }
  }

  .t-icon.icon-angle-right-small::before,
  .t-icon.icon-angle-left-small::before {
    color: $color-success;
  }

  .empty-image {
    width: 100%;
    height: 180px;
    background: $color-grey-lighter;
  }

  .card-wrapper {
    min-height: 290px;
  }

  .link {
    text-decoration: none;
    color: unset;
  }

  .image-white-overlay {
    content: '';
    width: 100%;
    height: 180px;
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(255, 255, 255, 0.35);
    display: block;
    z-index: 1;
  }

  .card-booking {
    padding-top: 0;
    padding-left: 0;
    padding-right: 0;
    height: 310px;

    .image-section {
      .category {
        height: 32px;
        padding: 7px 8px;
        border-radius: 4px;
        bottom: 0;
        left: 0;
        z-index: 99;
        margin-bottom: 16px;
        margin-left: 16px;
        color: $color-white;
        font-weight: 600;
      }
    }

    .img-card {
      border-radius: 4px 4px 0 0;
      height: 180px;
    }
  }
}
