@import "@/Library/UiKit/src/scss/uikit/utilities/variables/_variables.scss";
@import "@/Library/UiKit/src/scss/uikit/mixins/_mixins-responsive.scss";

.c-search-top {
  display: block;
  position: relative;

  /deep/ .t-input-group {
    max-width: 355px;

    @include mobile {
      min-width: auto;
      max-width: 100%;
    }
  }

  .t-section-extra-small {
    @include mobile {
      display: none;
    }
  }

  .trv-mobile-wrapper {
    .t-form {
      padding: 14px 20px;
    }
  }

  .t-floating-top-filter {
    top: auto !important;
    z-index: 15 !important;
    transition: all 0.8s ease-in-out;
  }

  .no-result-wrapper {
    @include media-down(sm) {
      padding-top: 48px !important;
    }
  }
}
