@import "@/Library/UiKit/src/scss/uikit/utilities/variables/_variables.scss";

.c-contactus-page {
  max-width: 768px;
  margin: auto;

  a {
    font-weight: 600;
    color: $color-grey-darkest;
    text-decoration: underline;

    &:hover {
      text-decoration: underline;
    }
  }
}
