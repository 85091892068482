@import "@/Library/UiKit/src/scss/uikit/utilities/variables/_variables.scss";
@import "@/Library/UiKit/src/scss/uikit/mixins/_mixins-responsive.scss";

.#{$travlr-ui} {
  .c-section {
    .t-heading-3 {
      font-size: 20px;

      @include media-up(md) {
        font-size: 24px;
      }
    }

    .title-container > div {
      &:first-child {
        padding-right: 10px;
      }
    }

    .t-btn-small {
      line-height: 1.4;
      height: auto;
      padding: 8px 16px !important;
      min-width: 110px;
      width: min-content;

      @include media-up(sm) {
        min-width: unset;
        width: auto;
      }
    }

    &.c-section--multiple {
      .t-container {
        @include media-up(sm) {
          padding-right: 24px;
        }

        @include media-up(md) {
          padding-right: 32px;
        }

        @include media-up(xl) {
          padding-right: 8px;
        }
      }

      .section-title {
        @include media-up(sm) {
          padding-right: 0;
        }
      }

      .c-carousel {
        margin-right: -8px;

        @include media-up(sm) {
          margin-right: -16px;
        }

        @include media-up(md) {
          margin-right: 0;
          padding-right: 8px;
        }

        @include media-up(xl) {
          padding-right: 0;
        }
      }
    }

    &.c-section--three {
      .c-carousel {
        .carousel-item {
          @include media-up(md) {
            width: 47%;
          }

          @include media-up(lg) {
            width: 33.33333%;
          }
        }
      }
    }

    &.c-section--four {
      .c-carousel {
        .carousel-item {
          @include media-up(md) {
            width: 47%;
          }

          @include media-up(lg) {
            width: 32%;
          }

          @include media-up(xl) {
            width: 25%;
          }
        }
      }
    }

    .section-button {
      &-responsive {
        @include media-down(lg) {
          height: auto;
          line-height: 1.2;
          padding: 8px 16px;
        }
      }

      @include media-down(lg) {
        display: none !important;
      }
    }
  }
}
