@import '~flickity/dist/flickity.css';
@import "@/Library/UiKit/src/scss/uikit/utilities/variables/_variables.scss";
@import "@/Library/UiKit/src/scss/uikit/mixins/_mixins-responsive.scss";

.c-carousel-card {
  position: relative;

  & .carousel-item {
    padding-right: 8px;
    padding-left: 8px;
  }

  & /deep/ .flickity {
    &-prev-next-button {
      top: 0;
      height: calc(100% - 20px);
      border-radius: 0;
      transform: none;
      background: rgba(0, 0, 0, 0.3);
      width: 48px;

      @include mobile {
        display: none !important;
      }

      &.next {
        right: 8px;
      }

      &.prev {
        left: 0;
      }

      &:disabled {
        opacity: 0;
        visibility: hidden;
      }
    }

    &-button-icon {
      path {
        fill: white;
      }
    }
  }
}
