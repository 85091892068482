@import "@/Library/UiKit/src/scss/uikit/utilities/variables/_variables.scss";
@import "@/Library/UiKit/src/scss/uikit/mixins/_mixins-responsive.scss";

.c-myquote-page {
  .quote-empty {
    min-height: 480px;
  }

  .selectbox {
    &__select {
      padding-bottom: 12px;
      padding-top: 12px;
    }

    &__icon {
      font-size: 20px;
    }

    &--sort {
      width: 200px;

      @include mobile {
        width: 100%;
      }
    }
  }

  .form-input__field {
    width: 400px;

    @include mobile {
      width: 100%;
    }
  }

  .flickity-prev-next-button {
    .flickity-button-icon {
      width: 18px;
      height: 18px;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}
