@import "@/Library/UiKit/src/scss/uikit/mixins/_mixins-prefixer";
@import "@/Library/UiKit/src/scss/uikit/mixins/_mixins-transform";
@import "@/Library/UiKit/src/scss/uikit/mixins/_mixins-animations";

.collapsible {
  &__action {
    overflow: hidden;

    @include transition(height 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94));
  }

  &__button {
    font-size: 16px;
    font-weight: bold;
    margin-top: 14px;
    padding: 0;

    &__icon {
      margin-left: 2px;
      position: relative;

      @include transition(transform 0.2s ease);
      vertical-align: middle;
    }
  }

  &-opened {
    & .collapsible {
      &__button {
        &__icon {
          @include transform(rotate(-180deg));
        }
      }
    }
  }
}

.t-bookings-accommodation-detail {
  .collapsible__button {
    font-size: 14px;
    margin-top: 8px;
  }
}
