@import "@/Library/UiKit/src/scss/uikit/utilities/variables/_variables.scss";
@import "@/Library/UiKit/src/scss/uikit/mixins/_mixins-responsive.scss";

.c-custom-article-section-layer-widget {
  .article-wrapper {
    flex-wrap: wrap;
  }

  .article-content {
    height: 135px;

    &::after {
      content: '';
      position: absolute;
      width: 100%;
      height: 20px;
      background: linear-gradient(180deg, hsla(0, 0%, 100%, 0.1), hsla(0, 0%, 100%, 0.3) 50%, hsla(0, 0%, 100%, 0.75) 75%, hsla(0, 0%, 100%, 0.95));
      z-index: 1;
      bottom: 0;
      left: 0;
    }
  }

  .t-icon {
    font-size: 16px;
  }

  .t-inner-html {
    /deep/ {
      font-size: 14px;
      line-height: 24px;

      p {
        margin-bottom: 0;
      }

      p,
      li {
        font-size: 14px;
        line-height: 24px;
        margin-bottom: 0;
      }

      a {
        text-decoration: underline;
        font-weight: 600;
        color: $color-grey-darkest;
      }

      ol,
      ul {
        padding-left: 20px;

        &:not(:last-child) {
          margin-bottom: 12px;
        }
      }

      ul {
        list-style: disc;
      }

      ol {
        list-style: decimal;
      }

      strong,
      b {
        font-weight: bold;
      }
    }
  }
}
