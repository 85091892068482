@import "@/Library/UiKit/src/scss/uikit/utilities/variables/_variables.scss";
@import "@/Library/UiKit/src/scss/uikit/mixins/_mixins-prefixer.scss";
@import "@/Library/UiKit/src/scss/uikit/mixins/_mixins-responsive.scss";

.c-subscription-plan-options {
  .subscription {
    &-plans {
      box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.08), 0 8px 8px -5px rgba(0, 0, 0, 0.04);
    }

    &-content {
      max-width: 800px;
      margin: auto;
    }

    &-steps {
      &::after {
        content: '';
        width: 100%;
        height: 2px;
        background: $color-grey-light;
        position: absolute;
        top: 52px;
        z-index: 0;
        max-width: 380px;

        @include mobile {
          max-width: 200px;
        }
      }

      .step {
        &-number {
          width: 40px;
          height: 40px;
          border-radius: 100%;

          &.disabled {
            cursor: not-allowed;
          }
        }
      }
    }
  }

  .option {
    &-badge {
      top: 0;
      right: 24px;
      width: 60px;
      background: #EF4444;

      &::after {
        content: '';
        position: absolute;
        height: 0;
        width: 0;
        bottom: -26px;
        left: 0;
        border-left: 30px solid #EF4444;
        border-right: 30px solid #EF4444;
        border-bottom: 26px solid transparent;
      }
    }
  }
}
