@import "@/Library/UiKit/src/scss/uikit/utilities/variables/_variables.scss";
@import "@/Library/UiKit/src/scss/uikit/mixins/_mixins-prefixer.scss";
@import "@/Library/UiKit/src/scss/uikit/mixins/_mixins-responsive.scss";

.c-subscription-thank-you {
  .subscription {
    &-content {
      max-width: 800px;
      margin: auto;
    }
  }

  .social-sharing {
    .social-icon {
      width: 30px;
      height: 30px;
      border-radius: 100%;

      .t-icon {
        font-size: 18px;
      }
    }
  }
}
