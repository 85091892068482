@import "@/Library/UiKit/src/scss/uikit/utilities/variables/_variables.scss";
@import "@/Library/UiKit/src/scss/uikit/mixins/_mixins-responsive.scss";

.travlr-ui {
  [class*="t-col"] {
    min-height: 1px;
  }

  & .t-md-w-600 {
    @include media-up(md) {
      max-width: 600px;
    }
  }

  & .t-h-10 {
    height: 10px;
  }

  & .t-background-success-light {
    background-color: rgba($color-success, 0.2);
  }

  & .t-footer-success {
    background-color: #FAFAFA;
    margin-left: -24px;
    margin-right: -24px;
    margin-bottom: -24px;
    margin-top: 32px;
  }

  & .t-m-t-md-0 {
    @include media-up(md) {
      margin-top: 0 !important;
    }
  }

  & .t-p-l-md-20 {
    @include media-up(md) {
      padding-left: 20px !important;
    }
  }

  .progress {
    &__label {
      > span {
        display: none;

        @include media-up(md) {
          display: inline-block;
        }
      }

      &--payment {
        left: 16px;

        @include media-up(md) {
          left: 55px;
        }
      }

      &--booking {
        left: 20px;

        @include media-up(md) {
          left: 60px;
        }
      }
    }

    &__line {
      border-bottom-color: $color-grey-lighter;
      border-bottom-width: 2px;
      border-bottom-style: solid;
      left: 0;
      top: 50%;
      transition: all 0.5s ease-in-out;
      transform: translateY(-50%);
      width: 0;

      &--expanded {
        width: 100%;
      }

      &--success {
        border-bottom-color: $color-success;
      }

      &--warning {
        border-bottom-color: $color-warning;
        border-bottom-style: dashed;
      }

      &--danger {
        border-bottom-color: $color-danger;
      }
    }

    &__circle {
      border-radius: 10px;
      height: 10px;
      top: 50%;
      transform: translateY(-50%);
      width: 10px;

      &::before {
        content: '';
        animation: blink 1.2s alternate infinite;
        animation-delay: 1.5s;
        border-radius: 15px;
        height: 15px;
        left: 50%;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 15px;
      }

      &--start {
        left: 0;
      }

      &--end {
        right: 0;
      }

      &--inprocess {
        &.t-background-success {
          &::before {
            background-color: rgba($color-success, 0.35);
          }
        }

        &.t-background-warning {
          &::before {
            background-color: rgba($color-warning, 0.35);
          }
        }

        &.t-background-danger {
          &::before {
            background-color: rgba($color-danger, 0.35);
          }
        }
      }
    }
  }
}

@keyframes blink {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
