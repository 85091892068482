@import "@/Library/UiKit/src/scss/uikit/utilities/variables/_variables.scss";
@import "@/Library/UiKit/src/scss/uikit/mixins/_mixins-responsive.scss";
@import "~/assets/scss/variables.scss";

.c-card-quote {
  .product-image {
    height: 120px;

    @include mobile {
      height: 220px;
    }
  }

  .card-action {
    display: flex !important;
  }

  .vertical-line {
    border-right: 1px solid $color-grey-300;

    @include mobile {
      border-right: 0;
      border-bottom: 1px solid $color-grey-300;
    }
  }
}
