@import "@/Library/UiKit/src/scss/uikit/utilities/variables/_variables.scss";
@import "@/Library/UiKit/src/scss/uikit/mixins/_mixins-prefixer.scss";
@import "@/Library/UiKit/src/scss/uikit/mixins/_mixins-animations.scss";
@import "@/Library/UiKit/src/scss/uikit/mixins/_mixins-responsive.scss";
@import "@/Library/UiKit/src/scss/uikit/mixins/_mixins-transform.scss";
@import "~/assets/scss/variables.scss";

.c-universal-deal-duration-option {
  &.disabled {
    opacity: 0.5;
    cursor: not-allowed;
    pointer-events: none;
    display: none;
  }

  .option {
    &-wrapper {
      min-height: 96px;
      outline: $primary-border;
      border-top-left-radius: $radius;
      border-bottom-left-radius: $radius;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;

      @include mobile {
        border-radius: $radius;
        outline: 0;
      }

      &.active,
      &:hover {
        outline-color: $color-grey-700;
        outline-width: 2px;
        border-radius: $radius;
      }

      &.active {
        .option-arrow {
          transform: rotate(180deg);
        }
      }
    }

    &-item {
      @include transition($primary-transition);

      &.selected {
        font-weight: bold;

        .t-icon {
          color: $color-blue-500;
        }
      }

      &:hover {
        background-color: $color-grey-lightest;
      }
    }
  }

  .t-input-number-dropdown {
    padding: 8px 0 !important;
    border: $primary-border !important;
    box-shadow: 0 32px 72px 0 #32325D1F !important;
  }
}
