@import "@/Library/UiKit/src/scss/uikit/utilities/variables/_variables.scss";
@import "@/Library/UiKit/src/scss/uikit/mixins/_mixins-prefixer.scss";
@import "@/Library/UiKit/src/scss/uikit/mixins/_mixins-animations.scss";
@import "@/Library/UiKit/src/scss/uikit/mixins/_mixins-responsive.scss";
@import "@/Library/UiKit/src/scss/uikit/mixins/_mixins-transform.scss";
@import "~/assets/scss/variables.scss";

.c-universal-deal-search-result {
  &.disabled {
    opacity: 0.5;
    cursor: not-allowed;
    pointer-events: none;
    display: none;
  }

  /* group packages start */
  .accordion {
    &.group-item {
      border-radius: 12px;
    }

    &.package-info {
      .accordion__content {
        > div {
          border: 0;
        }
      }
    }

    &__content {
      > div {
        border: $primary-border;
        overflow: hidden;
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
        border-top: 0;

        div {
          &:first-child {
            .c-universal-sku {
              border-top: 0;
            }
          }

          .c-universal-sku {
            border-radius: 0;
            border-left: 0;
            border-right: 0;
            border-bottom: 0;
          }
        }
      }

      .c-universal-sku {
        border-radius: 0;
      }
    }

    &__button {
      padding: 16px 24px;

      .t-heading-5 {
        font-size: 20px;
      }

      @include mobile {
        padding: 16px;
      }
    }
  }

  /* group packages end */

  /* filter start */
  .filter {
    &-list {
      flex: 0 0 auto;

      ul {
        flex: 0 0 auto;
      }
    }

    &-item {
      border-radius: 100px;

      @include transition($primary-transition);

      &:hover,
      &.active {
        border-color: $color-grey-900;
        background-color: $color-grey-900;
        color: #FFF;
      }
    }

    &-reset {
      color: #6B7280;
    }
  }

  /* filter end */
}
