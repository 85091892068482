@import "@/Library/UiKit/src/scss/uikit/utilities/variables/_variables.scss";
@import "@/Library/UiKit/src/scss/uikit/mixins/_mixins-responsive.scss";

.c-text-accordion-layer-widget {
  .t-inner-html {
    /deep/ {
      font-size: 16px;
      line-height: 24px;

      p,
      span,
      li {
        font-size: 16px !important;
        line-height: 24px;
        margin-bottom: 0;
      }

      p {
        margin-bottom: 12px;

        &:last-child {
          margin-bottom: 0;
        }
      }

      a {
        text-decoration: underline;
        font-weight: 600;
        color: $color-grey-darkest;
      }

      ol,
      ul {
        padding-left: 20px;

        &:not(:last-child) {
          margin-bottom: 12px;
        }
      }

      ul {
        list-style: disc;
      }

      ol {
        list-style: decimal;
      }

      strong,
      b {
        font-weight: bold;
      }
    }
  }

  /deep/ {
    @media screen and (min-width: 768px) {
      .t-accordion-title {
        font-size: 18px;
      }
    }
  }
}
