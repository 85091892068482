.c-card-listing {
  &.t-card-content {
    overflow: unset;
  }

  .t-card-content-footer-left-location {
    display: -webkit-box;
    min-height: auto;
  }

  .t-card-content-footer-left {
    height: 62px;
  }
}
