@import "@/Library/UiKit/src/scss/uikit/mixins/_mixins-miscellaneous.scss";
@import "@/Library/UiKit/src/scss/uikit/mixins/_mixins-transform.scss";

.t-bookings-activity-detail {
  & .content-left-section {
    min-height: 1px;
  }

  .t-align-center {
    align-items: center;
  }

  .main-card {
    border-radius: 4px;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.2);

    .transaction-detail {
      line-height: 24px;
    }

    /deep/ .additional-info-content {
      p {
        margin-bottom: 8px;
      }
    }

    @media screen and (max-width: 767px) {
      flex-direction: column;

      .both-image-info {
        flex-direction: column;
      }
    }

    .main-card-child {
      padding: 24px;

      &.left {
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
      }

      &.right {
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
      }
    }
  }
}
