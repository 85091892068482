@import "@/Library/UiKit/src/scss/uikit/utilities/variables/_variables.scss";
@import "@/Library/UiKit/src/scss/uikit/mixins/_mixins-prefixer.scss";
@import "@/Library/UiKit/src/scss/uikit/mixins/_mixins-responsive.scss";

.c-deals-info-item {
  &.bordered {
    border-bottom: 1px solid #D1D5DB;
  }

  .inner-content {
    &.truncated {
      max-height: 150px;
      overflow: hidden;
    }
  }
}
