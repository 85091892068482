@charset "utf-8";

@import "@/Library/UiKit/src/scss/uikit/utilities/variables/_variables.scss";
@import "@/Library/UiKit/src/scss/uikit/mixins/_mixins-prefixer.scss";
@import "@/Library/UiKit/src/scss/uikit/mixins/_mixins-animations.scss";
@import "@/Library/UiKit/src/scss/uikit/mixins/_mixins-responsive.scss";
@import "@/Library/UiKit/src/scss/uikit/mixins/_mixins-transform.scss";

.c-flight-search-sequences {
  & .icon-flight {
    @include transform(rotate(45deg));
    margin-top: -2px;
  }

  & .flight {
    &-detail {
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
      border-top: 1px solid $color-grey-lighter;

      &-popup {
        height: 0;
        overflow: hidden;

        @include transition($primary-transition);

        &.active {
          height: auto;
          overflow: auto;
        }

        &-info {
          &-vertical {
            &-line {
              border: 1px dashed $color-grey-lighter;
              margin: 0 auto;
              height: 90px;
              width: 1px;
            }

            &-circle {
              width: 8px;
              height: 8px;
              background-color: #FFF;
              border: 1px solid $color-tertiary;
              border-radius: 10px;
              margin: 0 auto;

              &-end {
                background-color: $color-tertiary !important;
              }
            }
          }

          &-airlines {
            background: rgba(153, 214, 234, 0.1);
          }
        }
      }

      &-section {
        @include mobile {
          float: left !important;
        }
      }
    }

    &-segment {
      &-line {
        margin: 0 auto;
        width: 50px;
        height: 1px;
      }
    }

    &-stops {
      &-line {
        border-top: 1px solid rgba(0, 0, 0, 0.2);
      }
    }

    &-stop-duration {
      background-color: rgba($color-danger, 0.1);
    }

    &-paired {
      border-top: 1px solid $color-grey-lighter;

      &-circle {
        width: 20px;
        height: 20px;
        line-height: 20px;
        font-size: 12px;
        border-radius: 100%;
        font-weight: bold;
      }
    }

    &-itinerary {
      @include mobile {
        float: left !important;
      }
    }

    &-airline-section,
    &-price {
      @include mobile {
        float: left !important;
      }
    }
  }

  & .flight-sequence {
    &-title {
      width: 28px;

      &-circle {
        width: 28px;
        height: 28px;
        line-height: 28px;
        border-radius: 100%;
        font-size: 16px;
      }
    }

    &-detail {
      background-color: rgba($color-primary, 0.05);
      border: 1px solid $color-secondary;
    }
  }
}
