@import "@/Library/UiKit/src/scss/uikit/utilities/variables/_variables.scss";
@import "@/Library/UiKit/src/scss/uikit/mixins/_mixins-prefixer.scss";
@import "@/Library/UiKit/src/scss/uikit/mixins/_mixins-responsive.scss";

.t-background {
  border-radius: 100%;
  height: 40px;
  width: 40px;
  min-width: 40px;
}

.t-background-circle {
  font-size: 12px;
  width: 20px;
  height: 20px;
  border-radius: 100%;
}

.claim-listing-main-content {
  .claim-banner-content {
    height: 475px;

    .claim-banner-data {
      .search-bar {
        top: 0;
        margin: 20px auto;
        left: 0;
        height: 72px;
        border: 2px solid #2DCCD3;
        max-width: 650px;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
        bottom: -30px;
        padding: 0 10px 0 10px;

        form {
          .search-icon {
            font-size: 32px;
          }
        }

        input[type="text"] {
          flex: 1;
          font-size: 18px;
          color: #979797;
          outline: none;
        }

        input[type="submit"] {
          height: 48px;
          font-size: 18px;
          font-weight: 700;
        }
      }
    }
  }
}
