@import "@/Library/UiKit/src/scss/uikit/utilities/variables/_variables.scss";
@import "@/Library/UiKit/src/scss/uikit/mixins/_mixins-responsive.scss";

.deal-bottom-filter {
  margin-top: ($grid-gutter-width * 4);

  & .selectbox {
    &--money {
      width: 162px;
      z-index: 10;

      @include media-up(lg) {
        width: 168px;
      }

      & .selectbox {
        &__dropdown {
          width: 240px;
        }
      }
    }

    &__select {
      padding-bottom: 12px;
      padding-top: 12px;
    }

    &__icon {
      font-size: 20px;
    }

    & .money-range {
      width: 275px;
    }

    &--sort {
      display: inline-block;
      font-size: 14px;
      text-align: left;
      width: 200px;
    }
  }

  & .mobile-bottom-filter {
    & .t-btn--reset {
      &.active {
        color: $color-primary;
      }
    }

    & .money-range {
      box-shadow: none;
      border: none;
      padding: 0;

      &__inner {
        .separator {
          width: 20%;
        }

        > .t-position-relative {
          width: 45%;
        }
      }
    }

    & .t-box {
      .t-text-size-body {
        font-weight: 600;
      }
    }
  }
}
