@import "@/Library/UiKit/src/scss/uikit/utilities/variables/_variables.scss";
@import "@/Library/UiKit/src/scss/uikit/mixins/_mixins-prefixer.scss";
@import "@/Library/UiKit/src/scss/uikit/mixins/_mixins-placeholders.scss";
@import "@/Library/UiKit/src/scss/uikit/mixins/_mixins-animations.scss";
@import "@/Library/UiKit/src/scss/uikit/mixins/_mixins-responsive.scss";
@import "@/Library/UiKit/src/scss/uikit/mixins/_mixins-text.scss";
@import "@/Library/UiKit/src/scss/uikit/mixins/_mixins-icon.scss";
@import "@/Library/UiKit/src/scss/uikit/mixins/_mixins-opacity.scss";
@import "@/Library/UiKit/src/scss/uikit/mixins/_mixins-border-radius.scss";
@import "@/Library/UiKit/src/scss/uikit/mixins/_mixins-miscellaneous.scss";
@import "@/Library/UiKit/src/scss/uikit/mixins/_mixins-transform.scss";

.t-booking-car-rental-detail {
  .main-card {
    border-radius: 8px;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.2);

    .main-card-child {
      padding: 24px;

      &.left {
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
      }

      &.right {
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
      }
    }

    .t-btn-view-voucher {
      padding-top: 0;
      padding-left: 24px;
      padding-right: 24px;
      text-align: center;
      padding-bottom: 24px;
    }

    .t-background-car-detail {
      background-color: #F3F4F6;
      border-radius: 8px;
      padding: 16px;
    }

    .t-border-car-detail-section {
      border: 1px solid #E5E7EB;
      border-radius: 8px;
    }

    & .car-rental {
      &-detail {
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
        border-top: 1px solid $color-grey-lighter;

        &-popup {
          height: 0;
          overflow: hidden;

          &-info {
            &-vertical {
              &-line {
                border: 1px dashed #6B7280;
                margin: 0 auto;
                height: 35px;
                width: 1px;

                @include mobile {
                  height: 35px;
                }
              }

              &-circle {
                width: 12px;
                height: 12px;
                background-color: #FFF;
                border: 1px solid #374151;
                border-radius: 10px;
                margin: 0 auto;

                &-end {
                  background-color: #FFF !important;
                }
              }
            }
          }
        }
      }
    }

    .t-background-protection {
      background-color: #F3F4F6;
      border-radius: 8px;
      width: 100%;
      padding: 10px;
    }
  }

  &.t-voucher-image {
    display: inline-table;
  }

  .booking-detail {
    @include mobile {
      display: grid !important;
    }
  }

  .t-car-mobile-padding {
    @include mobile {
      padding-left: 100px;
    }
  }

  .t-detail-location {
    @include mobile {
      display: contents;
    }
  }
}
