.c-flight-search-left-filter {
  /deep/ {
    & .t-btn-group {
      width: 100% !important;

      &-item {
        width: 50% !important;
      }
    }
  }
}
