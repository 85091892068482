@import "@/Library/UiKit/src/scss/uikit/utilities/variables/_variables.scss";
@import "@/Library/UiKit/src/scss/uikit/mixins/_mixins-responsive.scss";

.#{$travlr-ui} {
  & .country-code {
    &__select {
      opacity: 0;
    }

    &__caret {
      font-size: 14px;
      padding-left: 15px;
      padding-right: 30px;
      pointer-events: none;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}
