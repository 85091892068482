@import "@/Library/UiKit/src/scss/uikit/utilities/variables/_variables.scss";

.c-rating {
  & .rating-wrapper {
    unicode-bidi: bidi-override;
    margin: 0 auto;
    padding: 0;
    line-height: 0;

    & .star-item {
      font-size: 16px;
      display: table-cell;
    }
  }

  & .t-display-table-cell-important {
    display: table-cell !important;
  }
}
