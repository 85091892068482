@import "@/Library/UiKit/src/scss/uikit/utilities/variables/_variables.scss";
@import "@/Library/UiKit/src/scss/uikit/mixins/_mixins-responsive.scss";

.t-allotment-confirmation {
  @include mobile {
    background-color: white;

    & .t-confirmed-item {
      ::placeholder {
        font-size: 12px;
        text-overflow: ellipsis;
      }
    }
  }

  .t-booking-info {
    border: 1px solid $color-grey-dark;
    border-radius: $radius-large;
  }

  .t-button-confirm {
    border: 1px solid $color-grey-dark;
    color: $color-grey-dark;
  }

  .t-button-reject {
    border: 1px solid $color-grey-dark;
    color: $color-grey-dark;
  }

  .t-button-confirmed-booking {
    background: #3B82F6;
  }

  .t-button-customer-support {
    background: #3B82F6;
  }

  .t-separator {
    margin: 0;
  }

  .t-input__input {
    border: 1px solid $color-grey-dark;
    height: 48px;

    &:focus {
      border-color: $color-primary;
    }
  }
}
