@import "@/Library/UiKit/src/scss/uikit/utilities/variables/_variables.scss";
@import "@/Library/UiKit/src/scss/uikit/mixins/_mixins-prefixer.scss";
@import "@/Library/UiKit/src/scss/uikit/mixins/_mixins-responsive.scss";
@import "~/assets/scss/variables.scss";

$extra-wrapper: 600px;

.t-background-grey-100 {
  background-color: $color-grey-100;
}

.t-text-italic {
  font-style: italic !important;
}

.c-universal-deal-extras-option {
  top: 0;
  left: 0;
  z-index: 2000;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;

  &.active {
    opacity: 1;
    visibility: visible;
    pointer-events: fill;

    .extra-wrapper,
    .extra-overlay {
      opacity: 1;
      visibility: visible;
      pointer-events: fill;
    }
  }

  &.disabled {
    opacity: 0.5;
    cursor: not-allowed;
    pointer-events: none;
    display: none;
  }

  .extra {
    &-list {
      padding-bottom: 100px;

      @include mobile {
        padding-bottom: 160px;
      }
    }

    &-item {
      &.active {
        border-color: #3B82F6;
        border-width: 2px;
      }

      &.disabled {
        opacity: 0.5;
        cursor: not-allowed;
        pointer-events: none;
      }
    }

    &-overlay {
      top: 0;
      left: 0;
      z-index: 1010;
      background: rgba(0, 0, 0, 0.5);
      opacity: 0;
      visibility: hidden;
      pointer-events: none;
    }

    &-wrapper {
      width: $extra-wrapper;
      top: 0;
      right: 0;
      z-index: 1010;
      opacity: 0;
      visibility: hidden;
      pointer-events: none;

      @include mobile {
        max-width: 100%;
      }
    }

    &-header {
      &.is-hotelbeds-tourradar {
        padding-bottom: 124px;

        @include mobile {
          padding-bottom: 160px;
        }
      }

      .featured-image {
        width: 88px;
        min-width: 88px;
        height: 88px;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }

    &-body {
      border-top: $primary-border;
      height: 100%;
    }

    &-footer {
      width: $extra-wrapper;
      bottom: 0;
      right: 0;
      border-top: $primary-border;

      @include mobile {
        max-width: 100%;
      }
    }

    &-close-btn {
      top: 32px;
      right: 32px;
    }

    &-image {
      width: 128px;
      height: 128px;
      flex: 0 0 auto;

      @include mobile {
        width: 100px;
        height: 100px;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    &-price {
      &-summary {
        border-bottom: $primary-border;
      }

      &-wrapper {
        flex: 0 0 auto;
      }
    }
  }

  .el-input-number {
    line-height: 38px !important;
  }

  .add-extras-btn {
    display: inline-flex !important;
  }
}
