@import "ClientApp/assets/scss/variables";

.tcn-checkout-container {
  margin-top: 25px;
  margin-bottom: 5px;
  padding-bottom: 10px;

  &--applied {
    border-bottom: 1px solid #EEE;
  }

  &__ticket {
    width: 24px;
    height: 24px;
    object-fit: contain;
  }
}

.add-gift-card {
  border: 1px solid #CCC;
  background-color: #FFF;
  border-radius: 4px;
  height: 56px;
  display: table;
  width: 100%;
  padding: 16px;

  &__ticket {
    width: 24px;
    height: 24px;
    object-fit: contain;
    display: table-cell;
    vertical-align: middle;
    margin-right: 16px;
  }

  &__title {
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #374044;
    display: table-cell;
    vertical-align: middle;
  }

  &__button {
    height: 18px;
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: right;
    color: #6BA4B8;
    border: 0;
    background: none;
    display: table-cell;

    &--disabled {
      color: #DDD;
    }
  }
}

.add-another-gift-card {
  margin-bottom: 15px;

  &__button {
    color: #6BA4B8;
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    line-height: 24px;
    vertical-align: middle;

    &--disabled {
      opacity: 30%;
    }
  }

  &__icon {
    vertical-align: middle;

    &--disabled {
      opacity: 30%;
    }
  }
}

.payment-options-list {
  &__title-info-icon {
    float: right;
    cursor: pointer;
  }
}

@media screen and (max-width: $screen-sm) {
  .add-gift-card {
    &__button {
      display: block;
      text-align: center;
    }

    &__title {
      vertical-align: top;
    }
  }
}

@media screen and (max-width: $screen-xs) {
  .gift-card-checkout-info-popup {
    &__dialogue-container {
      width: 90%;
      padding: 24px 16px;
    }

    &__title {
      font-size: 20px;
      margin-bottom: 16px;
      margin-top: 36px;
    }

    &__subtitle {
      font-size: 14px;
      margin-bottom: 16px;
    }

    &__close-icon {
      display: block;
    }
  }
}
