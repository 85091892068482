@import "@/Library/UiKit/src/scss/uikit/utilities/variables/_variables.scss";
@import "@/Library/UiKit/src/scss/uikit/mixins/_mixins-responsive.scss";

.deal-search-content {
  margin-bottom: 40px;
  margin-top: 40px;

  @include media-up(md) {
    margin-top: 16px;
  }
}
