@import "@/Library/UiKit/src/scss/uikit/utilities/variables/_variables.scss";
@import "@/Library/UiKit/src/scss/uikit/mixins/_mixins-prefixer.scss";
@import "@/Library/UiKit/src/scss/uikit/mixins/_mixins-animations.scss";
@import "@/Library/UiKit/src/scss/uikit/mixins/_mixins-responsive.scss";
@import "@/Library/UiKit/src/scss/uikit/mixins/_mixins-transform.scss";
@import "~/assets/scss/variables.scss";

.c-universal-deal-guests-option {
  &.disabled {
    cursor: not-allowed;

    .option-wrapper {
      cursor: not-allowed !important;
      pointer-events: none;
      background: $color-grey-50;
      opacity: 0.5;
    }
  }

  .option-wrapper {
    min-height: 96px;
    outline: $primary-border;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;

    @include mobile {
      border-radius: $radius;
      outline: 0;
    }

    &.active,
    &:hover {
      outline-color: $color-grey-700;
      outline-width: 2px;
      border-radius: $radius;
    }

    &.active {
      .option-arrow {
        transform: rotate(180deg);
      }
    }
  }

  .t-input-number-dropdown {
    padding: 24px !important;
    border: 1px solid $color-grey-300 !important;
    box-shadow: 0 32px 72px 0 #32325D1F !important;
  }

  .el-input-number {
    span {
      line-height: 38px !important;
    }
  }
}
