@import "@/Library/UiKit/src/scss/uikit/utilities/variables/_variables.scss";
@import "@/Library/UiKit/src/scss/uikit/mixins/_mixins-prefixer.scss";
@import "@/Library/UiKit/src/scss/uikit/mixins/_mixins-transform.scss";

.c-flight-search-sort-filter {
  & .sort-filter-skeleton {
    height: 56px;

    &-item {
      height: 56px;
    }
  }

  /deep/ {
    & .t-tabs {
      max-height: none !important;

      &-item {
        &:focus,
        &:visited,
        &:hover,
        &.active {
          & .t-tabs {
            &-link {
              color: $color-grey-darkest !important;
            }
          }
        }
      }

      &-title {
        margin-right: 0 !important;
      }

      &-link {
        width: 100%;
        padding: 8px 16px;
        max-height: none;
        min-height: auto;
        line-height: normal;
        color: $color-grey-dark !important;
      }
    }

    & .t-card-skeleton {
      height: 56px;

      &-wrapper {
        top: 50%;
        left: 50%;

        @include transform(translate3d(-50%, -50%, 0));
      }
    }
  }
}
