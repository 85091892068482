@charset "utf-8";

@import "@/Library/UiKit/src/scss/uikit/utilities/variables/_variables.scss";
@import "@/Library/UiKit/src/scss/uikit/mixins/_mixins-prefixer.scss";
@import "@/Library/UiKit/src/scss/uikit/mixins/_mixins-animations.scss";
@import "@/Library/UiKit/src/scss/uikit/mixins/_mixins-responsive.scss";
@import "@/Library/UiKit/src/scss/uikit/mixins/_mixins-transform.scss";

.c-card-flight-checkout {
  & .icon-flight {
    @include transform(rotate(45deg));
    margin-top: -2px;
  }

  & .flight {
    &-header {
      background-color: rgba($color-primary, 0.05);
    }

    &-time {
      @include mobile {
        font-size: 14px;
      }
    }

    &-destination {
      @include mobile {
        font-size: 14px;
      }
    }

    &-detail {
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
      border-top: 1px solid $color-grey-lighter;

      &-popup {
        height: 0;
        overflow: hidden;

        @include transition($primary-transition);

        &.active {
          height: auto;
          overflow: auto;
        }

        &-info {
          &-vertical {
            &-line {
              border: 1px dashed $color-grey-lighter;
              margin: 0 auto;
              height: 90px;
              width: 1px;
            }

            &-circle {
              width: 8px;
              height: 8px;
              background-color: #FFF;
              border: 1px solid $color-tertiary;
              border-radius: 10px;
              margin: 0 auto;

              &-end {
                background-color: $color-tertiary !important;
              }
            }
          }

          &-airlines {
            background: rgba(153, 214, 234, 0.1);
          }
        }
      }
    }

    &-segment {
      &-line {
        margin: 0 auto;
        width: 50px;
        height: 1px;
      }
    }

    &-stops {
      &-line {
        border-top: 1px solid rgba(0, 0, 0, 0.2);
      }

      &-pin {
        width: 5px;
        height: 5px;
        border-radius: 100%;

        &-wrapper {
          left: 50%;
          top: -2px;

          @include transform(translateX(-50%));
        }
      }

      &-divider {
        background-color: rgba($color-danger, 0.1);
      }
    }

    &-stop-duration {
      background-color: rgba($color-danger, 0.1);
    }
  }

  & .airline {
    &-logo {
      width: 56px;
      height: auto;

      @include mobile {
        width: 40px;
      }

      &-small {
        width: 50px;
      }
    }
  }

  & .t-cards-wrapper {
    left: 50%;
    top: 50%;

    @include transform(translate3d(-50%, -50%, 0));
  }

  & .t-card-skeleton {
    &-line {
      margin-bottom: 12px;
    }

    &-image {
      height: 60px;

      &.small {
        height: 35px;
        max-width: 70%;
        margin: 0 auto;
      }
    }

    &-button {
      height: 48px;
    }

    &-price-wrapper {
      border-left: 1px dashed $color-grey-lighter;
      height: 93px;
    }

    &-pull-right {
      margin-left: 50% !important;
      margin-bottom: 0;
    }
  }
}
