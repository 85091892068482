@import "@/Library/UiKit/src/scss/uikit/utilities/variables/_variables.scss";
@import "@/Library/UiKit/src/scss/uikit/mixins/_mixins-prefixer.scss";
@import "@/Library/UiKit/src/scss/uikit/mixins/_mixins-responsive.scss";

.c-pagination-default {
  & .t-pagination {
    li {
      &.disabled {
        button {
          cursor: not-allowed;
        }
      }

      &.left-arrow,
      &.right-arrow {
        @include mobile {
          display: inline-block !important;
        }
      }
    }
  }
}
