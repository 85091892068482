@import "@/Library/UiKit/src/scss/uikit/utilities/variables/_variables.scss";
@import "@/Library/UiKit/src/scss/uikit/mixins/_mixins-responsive.scss";

.#{travlr-ui} {
  .deal-middle-filter {
    padding-top: 48px;

    @include media-up(md) {
      padding-top: 0;
    }

    & .t-tabs {
      border-radius: 0;
      outline: none;
      padding-left: 0;
      padding-right: 0;
      white-space: nowrap;

      & .t-tabs-inner {
        opacity: 0;
        visibility: hidden;
        transition: opacity 0.2s ease;

        &.visible {
          opacity: 1;
          visibility: visible;
        }
      }

      & .flickity-slider {
        transition: all 1.2s ease;
      }

      &.slide-animation-active {
        & .flickity-slider {
          transform: translateX(-10%) !important;
        }
      }

      &.slide-animation-inactive {
        & .flickity-slider {
          transition: none;
        }
      }
    }

    .flickity-button.next {
      background: linear-gradient(90deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.9) 50%, rgba(255, 255, 255, 0.95) 75%, rgba(255, 255, 255, 0.1));
    }

    .flickity-button.previous {
      background: linear-gradient(-90deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.9) 50%, rgba(255, 255, 255, 0.95) 75%, rgba(255, 255, 255, 0.1));
    }

    .flickity-prev-next-button {
      border-radius: 0;

      // hide scroll buttons on mobile
      height: 0;
      width: 0;

      @include media-up(md) {
        width: 100px;
        height: 100%;
      }
    }

    .flickity-button-icon {
      transform: scale(0.7);
    }

    /* position outside */
    .flickity-prev-next-button.previous {
      left: -40px;
    }

    .flickity-prev-next-button.next {
      right: -40px;
    }
  }
}
