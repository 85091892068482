@import "@/Library/UiKit/src/scss/uikit/utilities/variables/_variables.scss";
@import "@/Library/UiKit/src/scss/uikit/mixins/_mixins-prefixer.scss";
@import "@/Library/UiKit/src/scss/uikit/mixins/_mixins-responsive.scss";
@import "~/assets/scss/variables.scss";

.travlr-ui {
  .c-universal-deal-search-form {
    &.disabled {
      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        background: $color-grey-50;
        width: 100%;
        height: 100%;
        border-radius: 8px;
        opacity: 0.5;
        z-index: 5;
        cursor: not-allowed;
      }
    }

    @include mobile {
      background-color: $color-grey-100;
      border-radius: $radius-large;
    }
  }
}
