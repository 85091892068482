@import "@/Library/UiKit/src/scss/uikit/utilities/variables/_variables.scss";
@import "@/Library/UiKit/src/scss/uikit/mixins/_mixins-responsive.scss";
@import "~/assets/scss/variables.scss";

.c-quote-detail-page {
  .vti__dropdown.disabled,
  .vti__dropdown.open,
  .vti__dropdown:hover,
  .vue-tel-input {
    background-color: #FFF !important;
  }

  .product-image {
    height: 120px;

    @include mobile {
      height: 220px;
    }
  }

  .quote-status {
    &-draft {
      background-color: $color-grey-200;
      color: $color-grey-500;
    }

    &-pending {
      background-color: $color-yellow-50;
      color: $color-warning-v2;
    }

    &-booked {
      background-color: $color-green-50;
      color: $color-green;
    }
  }

  .disabled {
    opacity: 0.5;
    cursor: not-allowed !important;

    .vti__dropdown,
    input {
      cursor: not-allowed !important;
    }
  }
}
