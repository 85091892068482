@import '~vue-multiselect/dist/vue-multiselect.min.css';
@import "@/Library/UiKit/src/scss/uikit/mixins/_mixins-prefixer.scss";
@import "@/Library/UiKit/src/scss/uikit/mixins/_mixins-transform.scss";

.c-checkout-confirmation {
  & .c-checkout-confirmation-processing,
  & .c-checkout-confirmation-fraud-detected,
  & .c-checkout-confirmation-payment-failed,
  & .c-checkout-confirmation-payment-success,
  & .c-checkout-confirmation-booking-failed,
  & .c-checkout-confirmation-booking-success {
    margin-top: 30px;
  }

  & .btn-shortcut {
    width: 240px;
    max-width: 100%;
    display: inline-block;
    text-align: center;
  }

  & .finalise-payment {
    padding: 26px 24px;
    border-radius: 4px;
    -moz-border-radius: 4px;
    -webkit-border-radius: 4px;
    background-color: rgba(120, 204, 78, 0.1);
  }

  & .finalising-booking {
    background-color: #F4F4F4;
    padding: 26px 24px;

    &-spinner {
      top: 2px;
      position: relative;
    }
  }

  &-footer {
    background-color: #F4F4F4;
    border-radius: 0 0 4px 4px;
    -moz-border-radius: 0 0 4px 4px;
    -webkit-border-radius: 0 0 4px 4px;
    -o-box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);
    -ms-box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);
  }
}

.cart-status-loader {
  width: 100%;
  height: 100%;
  background-color: #FFF;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;

  &-icon {
    position: absolute;
    top: 35%;
    left: 50%;
  }

  &-oopsie {
    width: 100%;
    top: 35%;
    position: absolute;

    &-text {
      font-size: 16px;
      font-weight: 600;
      margin-bottom: 7px;
    }
  }
}

.cart-step {
  width: 100%;
  display: table;
  table-layout: fixed;

  &-label {
    text-align: left;
    font-size: 14px;
    font-weight: 600;

    &-center {
      left: -20%;
      position: relative;
    }

    &-desktop {
      display: block;
    }

    &-mobile {
      display: none;
    }

    @media screen and (max-width: 640px) {
      & .cart-step-label {
        font-size: 12px;

        &-desktop {
          display: none;
        }

        &-mobile {
          display: block;
        }
      }
    }

    &--success {
      color: #78CC4E;
    }

    &--processing {
      color: #78CC4E;
    }

    &--fraud {
      color: #F9C80E;
    }

    &--failed {
      color: #F45252;
    }

    &--incomplete {
      color: #CCC;
    }
  }

  &-item {
    padding: 0;
    position: relative;
    display: table-cell;

    &:first-child {
      & .circle-mark {
        left: 0;
      }
    }

    &:last-child {
      width: 100px;

      & .circle-mark {
        right: 10px;
      }
    }

    &:nth-of-type(2),
    &:nth-of-type(3) {
      left: 0;
    }

    & .circle-mark {
      position: absolute;
      display: block;
      top: 25.5px;
      border-radius: 50%;

      &::after {
        content: ' ';
        width: 10px;
        height: 10px;
        border-radius: 50px;
        position: absolute;
      }
    }

    /* Circle top */
    & .circle {
      &--success::after {
        background-color: #78CC4E;
      }

      &--processing::after {
        background-color: #78CC4E;
      }

      &--fraud::after {
        background-color: #F9C80E;
      }

      &--failed::after {
        background-color: #F45252;
      }

      &--incomplete::after {
        background-color: #DDD;
      }
    }

    /* Progress */
    & .progress {
      width: 100%;
      position: relative;
      border-radius: 0;
      box-shadow: none;
      margin-top: 14px;

      &--success {
        border-top: 2px solid #78CC4E;
      }

      &--processing {
        border-top: 2px solid #78CC4E;
      }

      &--fraud {
        border-top: 2px dashed #F9C80E;
      }

      &--failed {
        border-top: 2px solid #F45252;
      }

      &--incomplete {
        border-top: 2px solid #DDD;
      }
    }
  }
}

@media screen and (max-width: 640px) {
  & .cart-step-label-center {
    left: -16%;
  }
}

@media screen and (max-width: 480px) {
  & .c-checkout-confirmation & .c-checkout-confirmation-title-icon {
    font-size: 28px !important;
  }

  & .c-checkout-confirmation {
    &-title {
      font-size: 20px !important;
    }

    & .btn-shortcut {
      width: 100%;
    }
  }

  & .cart-step {
    margin-top: 24px;
    padding: 0 15px;

    & .circle-mark {
      top: 22.5px;
    }

    &-item:first-child {
      width: 118px;
    }

    &-item:nth-of-type(2) {
      width: 110px;
    }

    &-item:nth-of-type(3) {
      width: 80px;
    }

    &-item:last-child {
      width: 75px;
    }

    &-label {
      font-size: 12px;
    }
  }
}

@media screen and (max-width: 375px) {
  & .cart-step {
    &-item:first-child {
      width: auto;
    }

    &-item:nth-of-type(2) {
      width: auto;
    }

    &-item:nth-of-type(3) {
      width: auto;
    }

    &-item:last-child {
      width: auto;
    }

    &-label {
      font-size: 11px;
      left: 0;
    }
  }
}
