@import "@/Library/UiKit/src/scss/uikit/utilities/variables/_variables.scss";
@import "@/Library/UiKit/src/scss/uikit/mixins/_mixins-responsive.scss";

.travlr-ui {
  & .c-hero-main {
    position: relative;

    & .t-hero-main__overlay {
      background-color: rgba(102, 108, 111, 0.4);
      bottom: 0;
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
      z-index: 1;
    }
  }
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: #FFF;
  border: 1px solid #DEE2E6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto;
}
