@import "@/Library/UiKit/src/scss/uikit/utilities/variables/_variables.scss";
@import "@/Library/UiKit/src/scss/uikit/mixins/_mixins-prefixer.scss";
@import "@/Library/UiKit/src/scss/uikit/mixins/_mixins-responsive.scss";
@import "@/Library/UiKit/src/scss/uikit/mixins/_mixins-transform.scss";

.c-taxonomy-filter-wrapper {
  & .filter-modal-header {
    display: none;

    @include media-down(sm) {
      display: block;
      opacity: 0;
      padding: 16px;
    }
  }

  @include media-down(sm) {
    width: 100vw;
    height: 100vh;
    position: fixed !important;
    top: 0;
    left: 0;
    background: transparent;
    z-index: 1007;
    pointer-events: none;

    &.mobile-active {
      background: $color-grey-lightest;
      pointer-events: auto;

      & .filter-modal-header {
        opacity: 1;
      }

      & .c-taxonomy-filter {
        opacity: 1;
        pointer-events: auto;
      }
    }
  }

  & .c-taxonomy-filter {
    @include media-down(sm) {
      opacity: 0;
      pointer-events: none;
      padding: 32px;
    }

    & .dropdown-wrapper {
      @include mobile {
        padding-right: 0 !important;
      }
    }
  }

  & .mobile-filter-toggle {
    display: none;
    pointer-events: auto;
    position: fixed;
    bottom: 32px;
    left: 50%;
    z-index: 1500;

    @include transform(translateX(-50%));
    padding-left: 36px;

    & .icon-filter {
      left: 8px;
      top: 6px;
    }

    @include media-down(sm) {
      display: block;
    }

    &.hidden {
      opacity: 0;
    }
  }

  .t-btn-mobile-filter {
    bottom: 32px;
    left: 50%;
    pointer-events: auto;
    position: fixed;
    transform: translateX(-50%);
    z-index: 1500;
  }
}
