@import "@/Library/UiKit/src/scss/uikit/utilities/variables/_variables.scss";
@import "@/Library/UiKit/src/scss/uikit/mixins/_mixins-responsive.scss";

.c-video-hero-banner-layer-widget {
  .t-hero-main-background,
  .t-hero-main-content,
  .t-hero-main {
    height: calc(100vh - 64px);
    min-height: calc(100vh - 64px);
    max-height: calc(100vh - 64px);
  }

  .t-hero-main__overlay {
    z-index: 1;
    background-color: rgba(55, 64, 68, 0.15);
  }

  .t-hero-main-background {
    padding-top: 56.25%; // 16:9 Aspect Ratio (divide 9 by 16 = 0.5625)

    /deep/ {
      iframe {
        position: absolute;
        top: -50%;
        left: 0;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 200%;
        border: none;
      }
    }
  }

  .t-inner-html {
    max-width: 480px;
    margin-left: auto;
    margin-right: auto;

    /deep/ {
      font-size: 14px;
      line-height: 24px;

      p {
        margin-bottom: 0;
      }

      p,
      li {
        font-size: 14px;
        line-height: 24px;
        margin-bottom: 0;
      }

      a {
        text-decoration: underline;
        font-weight: 600;
        color: $color-grey-darkest;
      }

      ol,
      ul {
        padding-left: 20px;

        &:not(:last-child) {
          margin-bottom: 12px;
        }
      }

      ul {
        list-style: disc;
      }

      ol {
        list-style: decimal;
      }

      strong,
      b {
        font-weight: bold;
      }
    }
  }

  @media screen and (max-width: 767px) {
    .hero-title {
      font-size: 24px;
      line-height: 28px;
    }
  }

  @media screen and (max-width: 1024px) {
    .t-hero-main {
      height: auto !important;
      min-height: unset !important;
      max-height: inherit !important;

      &-background {
        position: relative !important;
      }

      &-container {
        position: absolute !important;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      &__overlay {
        top: 0;
        left: 0;
      }

      &-content,
      &-background {
        height: auto !important;
        min-height: unset !important;
        max-height: inherit !important;
      }
    }
  }
}
