@import "@/Library/UiKit/src/scss/uikit/utilities/variables/_variables.scss";
@import "@/Library/UiKit/src/scss/uikit/mixins/_mixins-prefixer.scss";
@import "@/Library/UiKit/src/scss/uikit/mixins/_mixins-animations.scss";
@import "@/Library/UiKit/src/scss/uikit/mixins/_mixins-responsive.scss";
@import "@/Library/UiKit/src/scss/uikit/mixins/_mixins-transform.scss";
@import "~/assets/scss/variables.scss";

.c-universal-deals-dates-option {
  &.disabled {
    opacity: 0.5;
    cursor: not-allowed;
    pointer-events: none;
    display: none;
  }

  .option-wrapper {
    min-height: 96px;
    outline: 1px solid transparent;
    border-radius: 0;

    @include mobile {
      border-radius: $radius;
      outline: 0;
      background-color: #FFF;
    }

    &.active,
    &:hover {
      outline: 1px solid $color-grey-700;
      border-radius: $radius !important;
      outline-width: 2px;
      background-color: #FFF;
    }

    &.active {
      .option-arrow {
        transform: rotate(180deg);
      }
    }
  }

  /* custom datepicker input */
  .el-date-editor--daterange {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
  }
}
