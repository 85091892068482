@import "@/Library/UiKit/src/scss/uikit/utilities/variables/_variables.scss";

.commision-info {
  cursor: pointer;
}

.c-modal-travel-raising-commission-info {
  .t-modal-content {
    width: 700px;
  }

  .commission-info-header {
    padding: 10px;
  }

  .commission-info-body {
    padding: 10px;

    .commission-info-item {
      padding: 13px 0;
      border-bottom: 1px solid $color-grey-lighter;

      &:last-child {
        border-bottom: none !important;
      }
    }
  }
}
