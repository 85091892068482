@import '~lightgallery.js/dist/css/lightgallery.min.css';

.c-slide-show {
  position: relative;

  .all-photos-btn {
    bottom: 16px;
    right: 16px;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 4px;

    button {
      width: 28px;
      height: 28px;

      .t-icon {
        font-size: 20px !important;
      }
    }
  }
}
