.t-flight-segment-detail {
  & .t-text-italic {
    font-style: italic;
  }

  & .t-flights-status {
    font-size: 14px;
  }

  & .t-display-flight-logo {
    width: 28%;
  }
}

.t-text-callout {
  font-weight: 700 !important;
}

.t-box-override {
  padding: 22px !important;
}
