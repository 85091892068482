@import "@/Library/UiKit/src/scss/uikit/utilities/variables/_variables.scss";
@import "@/Library/UiKit/src/scss/uikit/mixins/_mixins-responsive.scss";
@import "~/assets/scss/variables.scss";

.c-allotment-booking-widget {
  .deal-sku-v2__purchasable {
    @include tablet {
      flex-direction: column;
    }

    @include mobile {
      flex-direction: column;
    }
  }
}
