@import "@/Library/UiKit/src/scss/uikit/utilities/variables/_variables.scss";
@import "@/Library/UiKit/src/scss/uikit/mixins/_mixins-prefixer.scss";
@import "@/Library/UiKit/src/scss/uikit/mixins/_mixins-responsive.scss";
@import "@/Library/UiKit/src/scss/uikit/mixins/_mixins-miscellaneous.scss";
@import "~/assets/scss/variables.scss";

.travlr-ui {
  .c-deals-detail-v2 {
    .deals-tag {
      border: 1px solid $color-grey-300;
      border-radius: 8px;
      padding: 4px 12px;
      color: $color-grey-darkest;
    }

    .detail-inclusion {
      &__heading {
        border-bottom: 1px solid $color-grey-light;
      }

      &__body {
        ul {
          li {
            list-style: none;
            margin-bottom: 14px;
            position: relative;

            &:last-child {
              margin-bottom: 0;
            }
          }
        }
      }

      &__list {
        flex-wrap: wrap;

        .t-inner-html {
          /deep/ {
            p {
              margin-bottom: 0;
            }

            a {
              text-decoration: underline;
              color: $color-grey-darkest;
            }

            strong,
            b {
              font-weight: bold;
            }
          }
        }
      }
    }

    .detail-description {
      font-size: 14px;
      line-height: 24px;

      p,
      li {
        font-size: 14px;
        line-height: 24px;
        margin-bottom: 0;
      }

      a {
        text-decoration: underline;
        font-weight: 600;
        color: $color-grey-darkest;
      }

      ol,
      ul {
        padding-left: 20px;

        &:not(:last-child) {
          margin-bottom: 12px;
        }
      }

      ul {
        list-style: disc;
      }

      ol {
        list-style: decimal;
      }

      strong,
      b {
        font-weight: bold;
      }
    }

    .detail-filter {
      .nav-filter {
        .flickity-viewport {
          // replace plugin
          height: 55px !important;
        }

        &__item {
          float: left;

          a {
            border: 1px solid $color-grey-lighter;
            white-space: nowrap;
            border-radius: 28px;
            transition: none;
          }

          &.active {
            a {
              border-color: transparent;

              &::after {
                content: "";
                border-left: 10px solid transparent !important;
                border-right: 10px solid transparent !important;
                border-top: 10px solid $color-black;
                display: block;
                height: 0;
                left: 50%;
                position: absolute;
                top: 100%;
                transform: translateX(-50%);
                width: 0;
                z-index: 2;
              }
            }
          }
        }
      }
    }

    .detail-packages {
      .t-heading-1 {
        @include mobile {
          font-size: 24px;
        }
      }
    }

    .c-hotelbeds-booking-widget {
      .counter.active .counter__button:hover {
        color: #FFF !important;
      }
    }

    .icon-angle-left {
      font-size: 14px;
    }

    .icon-angle-right-small {
      top: 6px;
    }

    .flickity-button {
      display: none;

      @include media-up(md) {
        display: block;
      }
    }

    .t-slider-item {
      background-color: #333;
      padding-top: 66.66%; /* 3:2 Aspect Ratio (divide 2 by 3 = 0.6666)  */
      min-height: auto !important;
      max-height: none !important;

      .t-slider-item-img {
        height: auto;
        width: 100%;
      }
    }

    .all-photos {
      display: table;
      pointer-events: none;
      padding: 8px;
      bottom: 8px;
      left: 8px;
      background-color: rgba(0, 0, 0, 0.4);
      color: white;

      @include media-up(md) {
        display: none;
      }
    }

    .time-pressure-tag {
      background-color: $color-yellow-50;
      color: $color-warning-v2;
      border: 1px solid $color-warning-v2;
    }

    .detail-content {
      &__leading {
        padding: 16px 16px 0 16px;

        @include media-up(md) {
          padding-bottom: 16px;
          padding-top: 24px;
        }
      }

      &__packages {
        padding: 16px;

        > .t-heading-5 {
          margin-bottom: 16px;

          @include media-up(md) {
            margin-bottom: 24px;
          }
        }

        .accordion {
          &__button {
            background-color: $color-grey-lightest;
          }
        }
      }

      .leading-content {
        &__bottom {
          margin-top: 24px;

          @include media-up(lg) {
            display: none;
          }

          .redeem-date {
            margin-left: -16px;
            margin-right: -16px;
          }
        }
      }
    }

    .deals-quota-label {
      display: inline-block;
      font-size: 12px;
      line-height: 16px;

      &--box {
        border-radius: 4px;
        background-color: rgba($color-grey-darker, 0.1);
        padding: 6px 16px;

        &.deals-quota-label {
          &--danger {
            background-color: #FDEDED;
          }
        }
      }

      &--danger {
        color: $color-danger;
      }
    }

    .deails-info-wrapper {
      .c-deals-info-item {
        &:last-child {
          border-bottom: 0 !important;
          padding-bottom: 0;
        }
      }
    }

    // override deals-info-item component
    .c-deals-info-item {
      .collapsible__button {
        text-align: left;
        color: $color-grey-darkest;
      }

      .collapsible:not(.collapsible-opened) {
        .collapsible__button {
          position: relative;
          width: 100%;

          &::after {
            content: "";
            height: 40px;
            left: 0;
            position: absolute;
            top: -50px;
            width: 100%;
            z-index: 0;
            pointer-events: none;
          }
        }
      }
    }

    #title-section {
      .inner-wrapper {
        @include tablet {
          align-items: flex-start;
        }
      }
    }

    #about-section {
      .c-deals-info-item {
        .collapsible__button {
          &::after {
            background: linear-gradient(180deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.3) 50%, rgba(255, 255, 255, 0.75) 75%, rgba(255, 255, 255, 0.95));
          }
        }
      }
    }

    #information-section {
      background-color: #F9FAFB;

      .c-deals-info-item {
        .collapsible__button {
          &::after {
            background: linear-gradient(180deg, rgba(249, 250, 251, 0.1), rgba(249, 250, 251, 0.3) 50%, rgba(249, 250, 251, 0.75) 75%, rgba(249, 250, 251, 0.95));
          }
        }
      }

      .bne-icon {
        width: 28px;
      }
    }

    #planlater-section {
      .heading-inner {
        max-width: 600px;
        margin: 0 auto;
      }

      .icon {
        &-item {
          width: 40px;
          height: 40px;
          border-radius: 100%;
          align-items: center;
          justify-content: center;
        }
      }
    }

    #planlater-section,
    #policies-bottom-section {
      background-color: #F3F4F6;
    }

    #policies-section,
    #planlater-section,
    #policies-bottom-section {
      a {
        font-weight: 700;
        color: $color-grey-darkest;

        &:hover {
          text-decoration: underline;
        }
      }
    }

    #related-deals-section {
      background-color: $color-grey-200;
    }

    .bottom-divider {
      border-bottom: 1px solid $color-grey-300;
    }

    .sticky {
      &-info {
        left: 0;
        top: 0;
        z-index: 2000;
        opacity: 0;
        visibility: hidden;
        transition: all 0.3s ease;

        @include mobile {
          top: auto;
          bottom: 0;
          border-top: 1px solid $color-grey-300;
        }

        &.active {
          opacity: 1;
          visibility: visible;
          transition: all 0.3s ease;
        }
      }

      &-item {
        &::after {
          content: '';
          position: absolute;
          bottom: 0;
          height: 4px;
          width: 0;
          transition: all 0.5s ease;
          background-color: #000;
          right: 0;
        }

        &:hover {
          &::after {
            width: 100%;
            left: 0;
          }
        }
      }

      &-menu {
        @include mobile {
          display: none !important;
        }
      }

      &-price {
        @include mobile {
          width: 100%;
        }
      }
    }

    .sold-out-btn {
      background-color: $color-grey-400 !important;
      cursor: not-allowed;
    }

    button {
      &:disabled {
        background-color: $color-grey-light !important;
      }
    }
  }

  @media print {
    // hide elements
    .btn-checkin-date,
    .btn-toggle-info,
    .flickity-button,
    .deal-sku-v2 > .accordion.package-info {
      display: none !important;
    }

    .c-deals-detail {
      // container
      > .t-container > .t-row {
        margin: 0;

        > div {
          float: none;
          margin: 0 auto;
          padding: 0;
        }
      }

      .detail-content {
        box-shadow: none;
      }

      // section box
      .detail-content__leading,
      .detail-content__packages,
      .c-deals-info-panel,
      .detail-address {
        border: 1px solid $print-color-border;
        border-radius: 2px;
        box-shadow: none;
        margin-bottom: 20px;
      }

      // main section
      .detail-content__leading {
        padding: 16px !important;

        h1 {
          font-size: 18px;
          margin-bottom: 6px;
          margin-top: 4px;
        }

        .subtitle {
          font-size: 11px;
          margin: 0;
        }

        .print-address {
          font-size: 11px;
        }

        .print-slider {
          display: inline-block;
          padding-right: 8px;
          width: 68%;
        }

        .leading-content__bottom {
          border: 1px solid $print-color-border;
          border-radius: 2px;
          display: inline-block;
          margin-top: 0;
          margin-left: 4px;
          padding: 4px 2px 12px;
          vertical-align: top;
          width: calc(32% - 4px);
          height: 220px !important;

          .t-heading-3 {
            font-size: 16px;
            line-height: 1.2;
          }

          .t-body-text {
            font-size: 8px;
            display: block;
            line-height: 1;
            margin: 0;
          }

          .t-heading-3,
          .redeem-date {
            border: none;
            margin: 0;
            padding: 0;
          }

          .discount-tag {
            font-size: 10px;
            line-height: 1;
            margin-top: 0;
            margin-bottom: 2px;
          }
        }

        .print-qrcode {
          display: block !important;
          margin-bottom: 8px;
          padding-bottom: 12px;
          position: relative;

          &::after {
            content: "";
            border-bottom: 1px solid $print-color-border;
            position: absolute;
            bottom: 0;
            left: -2px;
            right: -2px;
            height: 1px;
          }

          img {
            margin-bottom: 4px;
            width: 105px;
          }

          p {
            font-size: 9px;
            font-weight: bold;
          }
        }
      }

      .deal-sku-v2__bottom {
        .sku-bottom__left {
          width: 100%;
        }
      }

      // slider
      .flickity-viewport {
        height: 220px !important;
        overflow: hidden !important;
      }

      .deal-sku-v2__middle {
        display: none !important;
      }

      .flickity-slider {
        transform: translateX(0) !important;
        display: block;
        height: 100%;

        > a {
          display: block;
          height: 100%;
          position: relative !important;
          top: 50%;
          transform: translate(0, -50%);
          padding: 0 !important;

          > img {
            height: 100% !important;
            width: 100% !important;
            position: relative;
            top: unset;
            left: unset;
            transform: unset;
            object-fit: cover;
          }
        }
      }

      // deal inclusions
      .detail-inclusion {
        &__heading {
          border: none;
          font-size: 11px;
        }

        &__body {
          font-size: 10px;
          line-height: 1.7;

          ul > li {
            list-style: disc;
            margin-bottom: 0;
            margin-left: 26px;
            padding-left: 20px;

            &::before {
              content: "";
            }
          }
        }
      }

      // deal list accordion
      .detail-packages {
        padding: 9px 14px;

        > .t-heading-5 {
          font-size: 11px;
          margin-bottom: 11px;
        }
      }

      .print-tabs > p {
        background-color: #7F7F7F;
        border-radius: 2px;
        font-size: 10px;
        display: inline-block !important;
        margin-bottom: 13px;
        padding: 6px 10px;
      }

      .accordion {
        box-shadow: none;
        border-color: #979797;
        display: block !important;
        margin-bottom: 10px;

        &:last-child {
          margin-bottom: 32px;
        }

        &-control > a {
          background-color: $color-white;
          padding-left: 12px;

          > h3 {
            font-size: 10px;
          }
        }

        &__content {
          height: auto !important;
        }
      }

      // deal list item
      .deal-sku-v2 {
        box-shadow: none;
        display: block !important;

        &__inner {
          display: inline-block;
          padding: 12px;
          vertical-align: top;
          width: 70%;

          > div {
            min-height: auto;
          }

          .deal-sku-v2__right {
            display: none !important;
          }
        }

        &__left {
          width: auto !important;

          .t-heading-6,
          .t-body-text {
            font-size: 10px !important;
            line-height: 1.6;
          }

          .t-heading-6 {
            margin-bottom: 4px;
          }
        }

        > div:last-child {
          display: inline-block !important;
          font-size: 12px;
          padding: 12px;
          text-align: right;
          vertical-align: top;
          width: 30%;

          p,
          span {
            font-size: 10px;
          }

          span {
            margin-bottom: 6px;
          }

          strong {
            font-size: 12px;
          }
        }
      }

      // overview
      .c-deals-info-panel {
        .info-panel__accordion {
          padding: 0 24px;
        }

        .accordion {
          &-control > a {
            color: $print-color-text;
            font-size: 10px;
            padding-bottom: 6px;
          }

          &__content > div > div {
            a {
              color: $print-color-text;
            }

            p {
              font-size: 10px;
              line-height: 1.5;
            }

            ul {
              padding-left: 32px;

              li {
                padding-left: 0;
              }
            }

            li {
              padding-left: 16px;
              font-size: 10px;
              line-height: 1.5;
            }
          }
        }
      }

      .discount-tag {
        background-color: transparent;
        color: $print-color-text;
        margin: 0;
        padding: 0;
      }
    }

    .tooltip {
      bottom: 100%;
      right: 0;
      width: 176px;

      &::after {
        content: "";
        border-left: 20px solid transparent;
        border-right: 20px solid transparent;
        border-top: 20px solid $color-grey-darkest;
        height: 0;
        position: absolute;
        right: 0;
        top: 100%;
        width: 0;
      }
    }
  }
}
