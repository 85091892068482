@import "@/Library/UiKit/src/scss/uikit/utilities/variables/_variables.scss";
@import "@/Library/UiKit/src/scss/uikit/mixins/_mixins-prefixer.scss";
@import "@/Library/UiKit/src/scss/uikit/mixins/_mixins-responsive.scss";
@import "@/Library/UiKit/src/scss/uikit/mixins/_mixins-transform.scss";

.travlr-ui {
  .c-shortlist-item-container {
    & .t-form {
      & .t-input-group {
        @include media-up(lg) {
          margin-bottom: 0;
        }

        & .t-input-global-datepicker {
          // replace ui kit style
          max-width: 100% !important;
          min-width: 100% !important;
        }
      }

      & .c-btn {
        text-align: center;

        @include media-up(xl) {
          text-align: left;
        }

        & .t-input-group {
          margin-bottom: 0;
        }
      }
    }

    & .guest-dropdown-counters-container {
      width: 100%;
      margin-right: 10px;

      & .guest-count-title {
        font-size: 14px;
        font-weight: 600;
        margin-bottom: 8px;
      }

      & .guest-count-btn {
        padding: 11px 16px;
        font-size: 16px;
        text-align: left;
        white-space: nowrap;
      }

      & .dropdown-container {
        z-index: 10;
        padding: 16px;
        margin-top: 8px;
        display: none;
        max-height: 400px;
        overflow-y: scroll;
        width: 100%;

        @include media-up(sm) {
          width: 320px;
        }

        &.active {
          display: block;
        }
      }
    }

    & .t-m-r-10 {
      @include tablet {
        margin-right: 0;
      }
    }

    & .t-w-25 {
      width: 25%;
    }

    & .t-m-b-0 {
      margin-bottom: 0;
    }

    & .t-background-transparent {
      background: transparent !important;
    }
  }
}
