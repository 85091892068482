.breadcrumb {
  ul {
    padding-bottom: 22px;
    padding-top: 22px;

    li {
      display: inline-block;
    }
  }

  span {
    margin: 0 10px;
    opacity: 0.6;
    vertical-align: middle;
  }

  a {
    color: #374044;
    font-size: 14px;

    &[href] {
      opacity: 0.6;

      &:hover,
      &:active,
      &:focus {
        opacity: 1;
      }
    }
  }
}
