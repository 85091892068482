@import "@/Library/UiKit/src/scss/uikit/utilities/variables/_variables.scss";
@import "@/Library/UiKit/src/scss/uikit/mixins/_mixins-responsive.scss";

.c-universal-sku {
  &.disabled {
    .t-btn-primary {
      background-color: $color-grey-med !important;
    }
  }

  .wrapper {
    &-left {
      .inner-content {
        @include media-up(md) {
          display: flex;
        }

        @include mobile {
          padding: 16px;
        }
      }
    }

    &-right {
      .inner-content {
        @include mobile {
          padding: 16px;
        }
      }
    }

    &-bottom {
      align-items: flex-end;
      display: flex;

      @include media-up(md) {
        display: none;
      }

      @include mobile {
        flex-direction: column;
      }

      & .t-btn {
        height: auto;
        line-height: 1;
        padding: 10px 16px;
      }

      .sku-bottom__left,
      .sku-bottom__right {
        @include mobile {
          width: 100%;
        }
      }
    }

    .btn-toggle-info {
      color: $color-grey-darkest;
      text-decoration: none;

      & .t-icon {
        position: relative;
        transition: transform 0.2s ease;
      }

      &.active {
        & .t-icon {
          transform: rotate(-180deg);
        }
      }
    }

    &-slideshow {
      width: 100%;

      @include media-up(md) {
        max-width: 180px;
      }

      .t-slider-item {
        height: 135px;
        min-height: auto !important;
      }

      .icon-image-library-o {
        background-color: rgba($color-grey-darkest, 0.5);
        bottom: 8px;
        left: 8px;
        padding: 4px 4px 3px 4px;
        pointer-events: none;
      }
    }

    &-datepicker {
      & .el-date-picker__header-label {
        cursor: default;
        color: $color-black;
        pointer-events: none;
      }

      & .el-picker-panel {
        &-body {
          pointer-events: none;

          &.loaded {
            pointer-events: initial;

            .el-picker-panel__content {
              &::after {
                opacity: 0;
                visibility: hidden;
              }
            }
          }
        }

        &-content {
          position: relative;

          &::after {
            content: "";
            animation: background_animation 900ms infinite alternate;
            background: $color-grey-lightest;
            border-radius: 4px;
            display: block;
            height: 100%;
            left: 0;
            position: absolute;
            top: 0;
            width: 100%;
            transition: all 0.1s ease;
            z-index: 100;
          }
        }
      }
    }

    .pricing-wrapper {
      flex-direction: column;
    }

    .room-left-badge {
      .t-alert-alt {
        display: inline-block;
        width: auto;

        &-body {
          padding: 4px 8px;
        }

        &-icon {
          display: none !important;
        }
      }
    }

    .cta-icon {
      font-size: 18px !important;
    }
  }

  .package-info {
    box-shadow: none !important;
    border-radius: 0 !important;
    margin-bottom: 0;

    &__content {
      // disable border on hotelbeds product
      &:not(.is-hotelbeds) {
        border-top: 1px dashed $color-grey-light;
      }

      ul {
        padding-left: 14px;

        @include media-up(lg) {
          padding-left: 18px;
        }

        li {
          list-style: disc;
        }
      }
    }

    p,
    ul {
      margin-bottom: 18px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .discount-label {
    color: #10B981;
    background-color: #ECFDF5;
  }
}

@keyframes background_animation {
  from {
    background-color: $color-grey-lightest;
  }

  to {
    background-color: $color-grey-lighter;
  }
}
