@charset "utf-8";

@import "@/Library/UiKit/src/scss/uikit/utilities/variables/_variables.scss";
@import "@/Library/UiKit/src/scss/uikit/mixins/_mixins-prefixer.scss";
@import "@/Library/UiKit/src/scss/uikit/mixins/_mixins-animations.scss";
@import "@/Library/UiKit/src/scss/uikit/mixins/_mixins-responsive.scss";
@import "@/Library/UiKit/src/scss/uikit/mixins/_mixins-transform.scss";

.c-line-loader {
  position: fixed;
  top: 64px;
  width: 100%;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;

  @include transition($primary-transition);

  &.active {
    opacity: 1;
    visibility: visible;
  }

  .line {
    width: 100%;
    height: 4px;
    background: #E0E0E0;
    overflow: hidden;
  }

  .loop {
    height: 4px;
    background: $color-primary;
    animation: 1700ms infinite loop cubic-bezier(0.42, 0, 0.58, 1);
  }

  @keyframes loop {
    0% {
      width: 0%;
      transform: translateX(0%);
    }

    7% {
      width: 10%;
      transform: translateX(0%);
    }

    40% {
      width: 60%;
    }

    50% {
      transform: translateX(85%);
    }

    75% {
      width: 100%;
    }

    100% {
      transform: translateX(100%);
    }
  }
}
