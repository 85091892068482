@import "@/Library/UiKit/src/scss/uikit/utilities/variables/_variables.scss";
@import "@/Library/UiKit/src/scss/uikit/mixins/_mixins-prefixer.scss";
@import "@/Library/UiKit/src/scss/uikit/mixins/_mixins-responsive.scss";

.c-flight-search-result {
  &.is-loading {
    min-height: 650px;
    max-height: 650px;
    overflow: hidden;
  }

  & .t-card-skeleton {
    &-left {
      &-mobile {
        @include mobile {
          float: left !important;
        }

        &.with-border {
          @include mobile {
            border-top: 1px dashed $color-grey-lighter;
          }
        }
      }
    }

    &-line {
      margin-bottom: 12px;
    }

    &-image {
      height: 60px;

      @include mobile {
        height: 42px;
      }

      &.small {
        height: 35px;
        max-width: 70%;
        margin: 0 auto;
      }
    }

    &-button {
      height: 48px;
    }

    &-price-wrapper {
      border-left: 1px dashed $color-grey-lighter;
      height: 93px;

      @include mobile {
        border-left: 0;
        height: auto;
      }
    }

    &-pull-right {
      margin-left: 50% !important;
      margin-bottom: 0;
    }
  }
}
