@import "@/Library/UiKit/src/scss/uikit/utilities/variables/_variables.scss";
@import "@/Library/UiKit/src/scss/uikit/mixins/_mixins-responsive.scss";

.travlr-ui {
  & .accordion-container {
    & .accordion {
      box-shadow: none;
      border-bottom: 1px solid $color-grey-lighter;

      &:last-child {
        border-bottom: none;
      }

      &__button {
        border-radius: 0;
        border: none;
        padding-left: 0;
        padding-right: 0;

        & .t-icon {
          right: 5px;
        }
      }
    }
  }
}
