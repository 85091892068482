.bordered-table {
  padding: 60px 80px;
  border: 1px solid #E0E0E0;
  box-sizing: border-box;
  border-radius: 8px;

  @media screen and (max-width: 767px) {
    padding: 40px 20px;
    margin-top: 30px;
  }
}
