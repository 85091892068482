@import "ClientApp/assets/scss/variables";

.gift-card-checkout-info-popup {
  width: 100%;
  height: 100%;

  &__inner-container {
    width: 100%;
    height: 100%;
    display: table-cell;
    vertical-align: middle;
  }

  &__dialogue-container {
    background-color: white;
    max-width: 650px;
    margin: auto;
    text-align: center;
    padding: 0 24px 24px 24px;
    border-radius: 4px;
    position: relative;
  }

  &__title {
    margin-bottom: 24px;
    font-size: 28px;
    font-weight: bold;
    text-align: center;
  }

  &__subtitle {
    margin-bottom: 24px;
    font-size: 16px;
    font-weight: 600;
  }

  &__bottom-container {
    padding-top: 16px;
    border-top: 1px #EEE solid;
  }

  &__bottom-container-text {
    text-align: left;
    font-size: 12px;
    margin-top: 0;
    color: #54585A;
    line-height: 1.5;
  }

  &__items-container p {
    margin-bottom: 1.4em;
    line-height: 1.5em;
  }
}
