@import "@/Library/UiKit/src/scss/uikit/utilities/variables/_variables.scss";
@import "@/Library/UiKit/src/scss/uikit/mixins/_mixins-prefixer.scss";
@import "@/Library/UiKit/src/scss/uikit/mixins/_mixins-responsive.scss";
@import '~leaflet/dist/leaflet.css';

.c-map-itinerary {
  width: 100%;

  & .map-itinerary {
    &-thumbnail {
      @include mobile {
        height: 240px !important;
      }
    }

    &-modal {
      height: 100%;
      top: 0;
      left: 0;
      bottom: 0;

      &-wrapper {
        position: relative;
        height: calc(80vh - 110px);
      }
    }
  }
}
