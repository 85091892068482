





























































































































































































































































@import "@/Library/UiKit/src/scss/uikit/utilities/variables/_variables.scss";
@import "@/Library/UiKit/src/scss/uikit/mixins/_mixins-responsive.scss";

.trv-refund-protect-quote-widget {
  .trv-refund-protect-skleton {
    .t-card-skeleton-image {
      width: 100%;
      height: 80px !important;
    }

    .custom-height-skeleton {
      height: 30px !important;
      margin-top: 50px;
    }

    .no-padding {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
  }

  .trv-refund-protect-content {
    .trv-refund-protect-content-container {
      padding: 24px;
      background: #F3F4F6;
      border-radius: 8px;
      border: 1px solid #D1D5DB;

      @include mobile {
        padding: 16px;
      }
    }
  }
}

  // BEGIN: Refund protect external class
  /deep/ .trv-refund-protect-content .refundRoot {
    padding: 0;
    background: transparent;
    border-left: unset;
    border-right: unset;

    input.yes[type="radio"]:checked + label::before {
      background: $color-success;
    }

    input.no[type="radio"]:checked + label::before {
      background: $color-danger;
      color: $color-white;
    }

    input.no[type="radio"]:checked + label {
      border: 1px solid $color-danger;
      color: $color-danger;
    }

    .refundTitle {
        background: transparent;
        margin-bottom: 16px;
    }

    label {
        background: #ffffff;
    }

    .refundSubHeader {
      padding-left: 24px;
      padding-right: 24px;
        a {
          text-decoration: underline !important;
        }
      }

    .refundFeaturesWrapper {
      font-size: 14px !important;
      padding-left: 24px;
      padding-left: 24px;
      .refundFeatures {
        display: unset;
        column-count: 2;
          strong {
            font-weight: normal;
          }
        .refundFeature {
          p {
            font-size: 14px;
            color: unset;
            font-weight: 400;
            line-height: 20px;
          }
        }
      }
    }

    .protectionText {
      font-size: 12px !important;
      line-height: 1.4 !important;
      padding-left: 24px;
      padding-right: 24px;

      a {
        text-decoration: underline !important;
      }
    }

    .protectionTextSuffix {
      p {
        strong {
          font-weight: normal !important;
        }
      }
    }

    .refundContent {
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;

      h3 {
        strong {
          font-weight: normal !important;
        }
      }

      .refundLabel {
        h3 {
          strong {
            font-weight: normal !important;
          }
        }
      }
    }

    .protectionTextSuffix {
      font-size: 12px !important;
      padding-bottom: 0.938em !important;
      background: #ffffff;
      padding-left: 24px;
      padding-right: 24px;
    }

    @media (min-width: 64.067em) {
      .refundTitle {
        .fa.fa-shield-alt {
          font-size: 28px !important;
        }

        h2 {
          font-size: 16px !important;
        }
      }

      .refundable {
        border-top-right-radius: 8px !important;
        border-top-left-radius: 8px !important;
        padding-left: 24px;
        padding-right: 24px;
      }

      .refundContent {
        h3 {
          font-size: 16px !important;
        }

        .refundHeaderBadge {
          font-size: 12px !important;
        }
      }

      .refundSubHeader {
        border-radius: unset !important;
        border-right: 1px solid #D1D5DB !important;
        border-left: 1px solid #D1D5DB !important;
      }

      .refundFeaturesWrapper {
        border-right: 1px solid #D1D5DB !important;
        border-left: 1px solid #D1D5DB !important;
        color: #111827;
        border-radius: unset !important;
        padding-right: 0.938em !important;
        background: #ffffff;
      }

      .protectionText {
        border-right: 1px solid #D1D5DB !important;
        border-left: 1px solid #D1D5DB !important;
        background: #ffffff;
      }

      .protectionTextSuffix {
        border-right: 1px solid #D1D5DB !important;
        border-left: 1px solid #D1D5DB !important;
        border-bottom: 1px solid #D1D5DB !important;
        border-bottom-left-radius: 8px !important;
        border-bottom-right-radius: 8px !important;
        background: #ffffff;
      }

      .nonRefundable {
        margin-top: 16px !important;
        border-radius: 8px !important;
      }
    }

    //Tablet
    @media (min-width: 767px) and (max-width: 1024px) {
      .refundFeaturesWrapper {
        border-radius: unset !important;
        border-bottom: unset !important;
        padding-right: 0.938em !important;
        background: #ffffff;
      }

      .protectionText {
        border-right: 1px solid #D1D5DB !important;
        border-left: 1px solid #D1D5DB !important;
        background: #ffffff
      }

      .protectionTextSuffix {
        border-right: 1px solid #D1D5DB !important;
        border-left: 1px solid #D1D5DB !important;
        border-bottom: 1px solid #D1D5DB !important;
        border-top-left-radius: unset;
        border-top-right-radius: unset;
        border-bottom-left-radius: 8px !important;
        border-bottom-right-radius: 8px !important;
        background: #ffffff;
      }

      .nonRefundable {
        margin-top: 16px !important;
        border-radius: 8px !important;
      }

      input.yes[type="radio"]:checked + label::before {
      background: $color-success;
      }

      input.no[type="radio"]:checked + label::before {
        background: $color-danger;
        color: $color-white;
      }

      input.no[type="radio"]:checked + label {
        border: 1px solid $color-danger;
        color: $color-danger;
      }

      .refundPrice {
        .refundPerTicket {
          font-size: 18px;
        }
      }

      .refundTitle {
        margin-bottom: 16px;
        i {
          font-size: 28px;
          padding-right: 12px;
        }
        .refundHeaderBadge {
          display: none;
        }
      }

      .refundContent {
        .refundLabel {
          display: unset;

          h3 {
            margin-bottom: 4px;
          }

          .refundHeaderBadge {
            margin-top: 4px;
            margin-left: unset;
            display: inline;
            font-size: 12px;
          }
        }
      }

      .inputFocus {
        width: unset;
        margin-left: 12px;
      }
    }

  // Mobile
  @media (max-width: 490px) {

    input.yes[type="radio"]:checked + label::before {
      background: $color-success;
    }

    input.no[type="radio"]:checked + label::before {
      background: $color-danger;
      color: $color-white;
    }

    input.no[type="radio"]:checked + label {
      border: 1px solid $color-danger;
      color: $color-danger;
    }

    .refundPrice {
      .refundPerTicket {
        font-size: 18px;
      }
    }

    .refundable {
      padding-left: 16px;
      padding-right: 16px;
    }

    .refundSubHeader {
      padding-left: 16px;
      padding-right: 16px;
    }

    .refundFeaturesWrapper {
      border-radius: unset !important;
      border-bottom: unset !important;
      padding-right: 16px !important;
      padding-left: 16px;
      background: #FFFFFF;
    }

    .protectionText {
      border-right: 1px solid #D1D5DB !important;
      border-left: 1px solid #D1D5DB !important;
      padding-left: 16px;
      padding-right: 16px;
      background: #FFFFFF;
    }

    .protectionTextSuffix {
      border-right: 1px solid #D1D5DB !important;
      border-left: 1px solid #D1D5DB !important;
      border-bottom: 1px solid #D1D5DB !important;
      border-top-left-radius: unset;
      border-top-right-radius: unset;
      border-bottom-left-radius: 8px !important;
      border-bottom-right-radius: 8px !important;
      background: #FFFFFF;
      padding-left: 16px;
      padding-right: 16px
    }

    .nonRefundable {
      margin-top: 16px !important;
      border-radius: 8px !important;
    }

    .refundTitle {
      margin-bottom: 16px;
      i {
        font-size: 28px;
        padding-right: 12px;
      }
      .refundHeaderBadge {
        display: none;
      }
    }

    .refundContent {
      .refundLabel {
        display: unset;

        h3 {
          margin-bottom: 4px;
        }

        .refundHeaderBadge {
          margin-top: 4px;
          margin-left: unset;
          display: inline;
          font-size: 12px;
        }
      }
    }

    .inputFocus {
      width: unset;
      margin-left: 12px;
    }

    .refundFeatures {
      display: flex !important;
      flex-wrap: wrap;
    }

  }

  // END: Refund protect external class

}

