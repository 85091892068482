@import "~lightgallery.js/dist/css/lightgallery.min.css";
@import "@/Library/UiKit/src/scss/uikit/utilities/variables/_variables.scss";
@import "@/Library/UiKit/src/scss/uikit/mixins/_mixins-responsive.scss";

.c-photo-grid {
  height: 424px;

  @include tablet {
    height: 282px; // 3:2 aspect ratio: 2 ÷ 3 from desktop view
  }

  .pg-item {
    border-top: 8px solid #FFF;
    border-left: 8px solid #FFF;

    &:first-child {
      border-left: 0;
    }
  }

  .pg-image {
    object-fit: cover;
    height: 100%;
    width: 100%;

    &.item-0 {
      backdrop-filter: blur(50px);
    }
  }

  // 1 cells
  .pg-grid-1 .pg-item {
    width: 100%;
    height: 100%;

    .pg-image {
      object-fit: contain !important;
    }
  }
  // 1 cells end

  // 2 cells
  .pg-grid-2 .pg-item:nth-child(1) {
    width: 66.66667%;
  }

  .pg-grid-2 .pg-item {
    height: 100%;
  }

  .pg-grid-2 .pg-item:nth-child(2) {
    left: auto;
    right: 0;
    border-right: 0;
    width: 33.33333%;
  }
  // 2 cells end

  // 3 cells
  .pg-grid-3 .pg-item:nth-child(1) {
    width: 66.66667%;
    height: 100%;
  }

  .pg-grid-3 .pg-item:nth-child(2),
  .pg-grid-3 .pg-item:nth-child(3) {
    height: 50%;
    width: 33.33333%;
  }

  .pg-item:last-child,
  .pg-grid-3 .pg-item:nth-child(2),
  .pg-grid-3 .pg-item:nth-child(3) {
    left: auto;
    right: 0;
    border-right: 0;
  }

  .pg-grid-3 .pg-item:nth-child(3) {
    bottom: 0;
    top: auto;
  }
  // 3 cells end

  // 4 cells
  .pg-grid-4 .pg-item {
    width: 33.33333%;
  }

  .pg-grid-4 .pg-item:nth-child(3) {
    top: 0;
  }

  .pg-grid-4 .pg-item:nth-child(1),
  .pg-grid-4 .pg-item:nth-child(2) {
    height: 100%;
  }

  .pg-grid-4 .pg-item:nth-child(2) {
    left: 33.33333%;
  }

  .pg-grid-4 .pg-item:nth-child(3),
  .pg-grid-4 .pg-item:nth-child(4) {
    left: auto;
    right: 0;
    border-right: 0;
    height: 50%;
  }

  .pg-grid-4 .pg-item:nth-child(4) {
    bottom: 0;
    top: auto;
  }
  // 4 cells end

  // 5 cells
  .pg-grid-5 .pg-item {
    width: 25%;
    height: 50%;
  }

  .pg-grid-5 .pg-item:nth-child(1) {
    height: 100%;
    width: 50%;
  }

  .pg-grid-5 .pg-item:nth-child(2) {
    left: 50%;
    top: 0;
    bottom: auto;
  }

  .pg-grid-5 .pg-item:nth-child(3) {
    left: 50%;
    bottom: 0;
    top: auto;
  }

  .pg-grid-5 .pg-item:nth-child(4) {
    left: auto;
    right: 0;
    top: 0;
  }

  .pg-grid-5 .pg-item:nth-child(5) {
    bottom: 0;
    top: auto;
    right: 0;
  }
  // 5 cells end

  .all-photos-btn {
    bottom: 16px;
    right: 16px;

    .t-btn-inverted {
      background-color: #FFF;
    }
  }
}
