@import '~flickity/dist/flickity.css';

.c-carousel-slide-show {
  position: relative;

  & /deep/ .flickity {
    &-button {
      width: 48px;
      height: 48px;
      background: rgba(#374044, 0.5);

      &-icon {
        left: 25%;
        top: 25%;
        width: 50%;
        height: 50%;

        path {
          fill: white;
        }
      }
    }

    &-prev-next-button {
      &.next {
        right: 24px;
      }

      &.previous {
        left: 24px;
      }
    }
  }
}
